import { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import PropsContext from 'context/props'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import { getExtName } from 'component/Survey/Modules/19/FileUpload'
import UsedFilesizeComponent from './UsedFilesize'

interface ExtLists {
    img: string[];
    word: string[];
    sound: string[];
    video: string[];
    compression: string[];
}

const extLists:ExtLists = {
    img: ['jpg, jpeg', 'png', 'gif'],
    word: ['doc, docx', 'hwp, hwpx', 'ppt, pptx', 'xls, xlsx', 'csv', 'txt', 'pdf'],
    sound: ['mp3', 'ogg', 'wma', 'wav'],
    video: ['mp4', 'mov', 'wmv', 'avi'],
    compression: ['zip']
}

// 키값 배열화
const extKeyArray: Array<keyof ExtLists> = Object.keys(extLists) as Array<keyof ExtLists>

const checkboxStyle = { width: 16, height: 16, color: oc.gray[6] }
const titleBoxStyle = { display: 'flex', alignItems: 'center', gap: 5 }
const titleStyle = { fontSize: 13, fontWeight: 'bold', paddingLeft: 2, cursor: 'pointer' }
const itemBoxStyle = { display: 'flex', alignItems: 'center', gap: 8, flexWrap: 'wrap', marginTop: 2 }
const itemStyle = { display: 'flex', alignItems: 'center', gap: 1, fontSize: 12, cursor: 'pointer' }


// 선택 초기값 선택
const getInitChecked = (selectExts: string[]) => {

    let checkedList = {
        img: false,
        word: false,
        sound: false,
        video: false,
        compression: false
    }

    const len = extKeyArray.length

    for (let i=0; i<len; i++) {
        // key
        const key = extKeyArray[i]
        // keyValue
        const exts = extLists[key]
        const itemsLen = exts.length

        let flag = true

        // 타입별 확장자를 검색하여 모든 확장자가 선택된경우 전체 선택 디폴트는 해제(false)
        for (let j=0; j<itemsLen; j++) {
            const item = exts[j]
            if (!selectExts.includes(item)) {
                flag = false
                break
            }
        }

        checkedList[key] = flag
    }

    return checkedList
}

const initChecked: Record<keyof ExtLists, boolean>  = {
    img: false,
    word: false,
    sound: false,
    video: false,
    compression: false
}

function ExtsComponent() {
    const { states, onOption }: any = useContext(PropsContext)

    // changeExts
    const { row } = states
    const { _option } = row
    const { exts } = _option

    const [ checked, setChecked ] = useState(getInitChecked(exts))

    const { formatMessage: f } = useIntl()

    // 확장자 선택
    const handleChange = (checked: boolean, ext: string) => {
        if (checked) onOption.changeExts([...exts, ext])
        else onOption.changeExts(exts.filter(c => c !== ext))
    }

    // 타입별로 선택
    const handleChangeAll = (key: keyof ExtLists) => {
        const newChecked = !checked[key]
   
        // 타입별 상태값 변경
        setChecked(prev => ({
            ...prev,
            [key]: newChecked
        }))

        if (newChecked) onOption.changeExts([...exts, ...extLists[key]])
        else {
            onOption.changeExts(exts.filter(c => !extLists[key].includes(c)))
        }

    }

    useEffect(() => {
        return () => {
            setChecked(initChecked)
        }
    }, [])

    return (
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            {
                extKeyArray.map((key, i) => {
                    return (
                        <Paper key={key} variant="outlined" style={{padding: 5, marginTop: i === 0 ? 0 : 5}}>
                            <div style={titleBoxStyle}>
                                <Typography variant="body2" color="primary" style={titleStyle} onClick={() => handleChangeAll(key)}>{f({id: `component.Survey.Edit.19.Exts.subheader.${key}`})}</Typography>
                            </div>
                            <div style={itemBoxStyle}>
                            {
                                extLists[key].map(c => {
                                    const checked = exts.includes(c)
                                    return (
                                        <div 
                                            key={c} 
                                            style={itemStyle} 
                                            onClick={() => handleChange(!checked, c)}
                                        >
                                            { checked ? <CheckBoxIcon style={checkboxStyle} /> : <CheckBoxOutlineBlankIcon style={checkboxStyle} /> }
                                            {getExtName([c]).join('').toUpperCase()}
                                        </div>
                                    )
                                    
                                })
                            }
                            </div>
                        </Paper>
                    )
                })
            }
            <UsedFilesizeComponent/>
        </SubTabBox>
    )
}

export default ExtsComponent