import { useIntl } from 'react-intl'
import { fileSize } from 'ts-utils'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import useFileUploadSize from 'hooks/useFileUploadSize'

const Wrapper1 = withStyles({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
})(Box)

const Wrapper2 = withStyles({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& > * + *': {
            marginLeft: 10
        }
    }
})(Box)

const MyBox = withStyles(theme => ({
    root: (props: { width: number }) => {
        const { width } = props

        let styles:any = {
            borderRadius: 3
        }

        if (width < 198) {
            styles = {
                ...styles,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
            }
        }

        return {
            position: 'relative',
            width: 200,
            height: 15,
            borderRadius: 3,
            backgroundColor: theme.palette.action.disabledBackground,
            '& > div': {
                ...styles,
                position: 'absolute',
                width: width,
                height: 15,
                backgroundColor: theme.palette.secondary.dark
            }
        }
    }
}))(Box)

const MyTypography = withStyles(theme => ({
    root: {
        color: theme.palette.action.active
    }
}))(props => <Typography variant="body2" {...props}/>)

function UsedFilesizeComponent() {
    const { formatMessage: f } = useIntl() 

    const [{ limit, used }] = useFileUploadSize()

    let width = Math.round(used / limit * 100)
    width = width > 200 ? 200 : width

    return (
        <>
        <Wrapper1 style={{marginTop: 20, marginBottom: 5}}>
            <Typography variant="body2">{f({id: 'component.Survey.Edit.19.UsedFilesize.primary'})}</Typography>
        </Wrapper1>
        <Wrapper2>
            <MyBox width={width}>
                <div></div>
            </MyBox>
            <MyTypography>
                {fileSize(used, 2)} / {fileSize(limit)}   
            </MyTypography>
        </Wrapper2>
        </>
    )
}

export default UsedFilesizeComponent