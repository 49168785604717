import {useContext} from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { FormControlLabel, Checkbox, Radio, RadioGroup } from '@material-ui/core'
import OptionStyled from 'styled/Survey/Edit/Option'
import MobileScreenComponent from './MobileScreen'
import { CommentOpenComponent, CommnentSetupComponent } from '../01/Option'

// 모바일 화면 선택 라디오 그룹
export const MatrixMobileRadioGroup = (props) => {
    const { mobile_matrix, onChange } = props
    const { formatMessage: f } = useIntl()

    return (
        <RadioGroup row aria-label="mobile-matrix" style={{margin: 0}} name="mobile_matrix" value={mobile_matrix} onChange={onChange}>
            <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label={f({id: 'component.Survey.Edit.03.Option.mobileMatrixSub.type.0'})}
                labelPlacement="end"
            />
            <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label={f({id: 'component.Survey.Edit.03.Option.mobileMatrixSub.type.1'})}
                labelPlacement="end"
            />
        </RadioGroup>
    )
}

function OptionComponent() {

    const { onOption, onEdit, states, refComment } = useContext(PropsContext)
    const { 
        required, 
        random_column, 
        except_last_random_column, 
        random_answer, 
        except_last_random_answer, 
        is_comment, 
        is_comment_required, 
        is_comment_placeholder,
        mobile_matrix
    } = states.row._option

    const { formatMessage: f } = useIntl()
    
    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={required} onChange={(e) => onOption.changeRequired(e)} name="required" color="primary"/>
                    } label={f({id:'component.Survey.Edit.03.Option.required'})}/>
                </div>
                
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={random_column} onChange={(e) => onOption.changeRandomColumn(e)} color="primary"/>
                    } label={f({id:'component.Survey.Edit.03.Option.randomColumn'})}/>
                </div>
                
            </div>
            <div className={`sub ${random_column ? 'open random' : ''}`}>
                <FormControlLabel control={
                    <Checkbox checked={except_last_random_column} onChange={(e) => onOption.changeExceptLastRandomColumn(e)} color="primary"/>
                } label={f({id:'component.Survey.Edit.03.Option.exceptLastRandomColumn'})}/>
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={random_answer} onChange={(e) => onOption.changeRandomAnswer(e)} color="primary"/>
                    } label={f({id:'component.Survey.Edit.03.Option.randomAnswer'})}/>
                </div>
                
            </div>
            <div className={`sub ${random_answer ? 'open random' : ''}`}>
                <FormControlLabel control={
                    <Checkbox checked={except_last_random_answer} onChange={(e) => onOption.changeExceptLastRandomAnswer(e)} color="primary"/>
                } label={f({id:'component.Survey.Edit.03.Option.exceptLastRandomAnswer'})}/>
            </div>
            <div className='line'></div>

            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox style={{color: 'black'}} checked={true} disabled={false} name="checkbox_mobile_matrix" color="primary" />
                    } label={f({id:'component.Survey.Edit.03.Option.mobileMatrix'})}/>
                    <MobileScreenComponent mobile_matrix={mobile_matrix}/>
                </div>
                
            </div>
            <div className="sub open isComment">
                <MatrixMobileRadioGroup mobile_matrix={mobile_matrix} onChange={onOption.changeMobileMatrix}/>
            </div>
            <div className='line'></div>


            <div className='main'>
                <CommentOpenComponent f={f} module_type="03" checked={is_comment} onChange={onOption.changeIsComment}/>
            </div>
            <div className={`sub last ${is_comment ? 'open isComment' : ''}`}>
                <CommnentSetupComponent
                    f={f}
                    checked={is_comment_required}
                    onChange={onOption.changeIsCommentRequired}
                    defaultValue={is_comment_placeholder}
                    refComment={refComment}
                    onChangeRef={onEdit.changeOption}
                />
            </div>
        </OptionStyled>
    )   
}

export default OptionComponent