import { useContext } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import oc from 'open-color'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

function Component(props) {
    const { children } = props
    const { states, change09Type } = useContext(PropsContext)
    
    const { subTabIndex, row } = states 
    const { _option } = row
    const { type } = _option

    const { formatMessage: f } = useIntl()

    return (
        <div style={{display: subTabIndex === 0 ? 'block' : 'none'}}>
            <FormControl fullWidth variant="filled">
                    <InputLabel id="demo-controlled-open-select-label" style={{ color: oc.indigo[9], fontWeight: 900, fontSize: 16 }}>
                        {f({id: 'component.Survey.Edit.09.TypeBase.label'})}
                    </InputLabel>
                    <Select 
                        value={type} 
                        variant="filled"
                        onChange={(e) => change09Type(e.target.value)} 
                        fullWidth 
                        label={f({id: 'component.Survey.Edit.09.TypeBase.label'})}
                        renderValue={(value) => {
                            const str2 = f({id: `component.Survey.Edit.09.Type${value.replace(/^[a-z]/, char => char.toUpperCase())}.typeLabel`})
                            return str2
                        }}
                    >
                        <MenuItem value="base">{f({id: 'component.Survey.Edit.09.TypeBase.typeLabel'})}</MenuItem>
                        <MenuItem value="number">{f({id: 'component.Survey.Edit.09.TypeNumber.typeLabel'})}</MenuItem>
                        <MenuItem value="email">{f({id: 'component.Survey.Edit.09.TypeEmail.typeLabel'})}</MenuItem>
                        <MenuItem value="phone">{f({id: 'component.Survey.Edit.09.TypePhone.typeLabel'})}</MenuItem>
                        <MenuItem value="date">{f({id: 'component.Survey.Edit.09.TypeDate.typeLabel'})}</MenuItem>
                    </Select>
                </FormControl>
            {children}
        </div>
           
    )
}

export default Component