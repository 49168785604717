/**
 * 범위 설정 달력
 * @returns 
 */
import { memo, useCallback, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import oc from 'open-color'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import CalendarComponent from './Calendar'
import { Props as ParentProps, OnClose } from './Range'

interface Props extends ParentProps {
    open: boolean;
    onClose: OnClose;
}

const getValue = (value: Props['value']) => {
    if (!value) {
        const today = moment().format('YYYY-MM-DD')
        return [today, today]
    }

    const [ start, end ] = value.split('~')

    return [ start, end ]
}

function RangeCalendarComponent(props: Props) {
    const { open, value, onChange, onClose } = props

    const { formatMessage: f } = useIntl() 

    const [ startValue, endValue ] = getValue(value)

    const [ startDate, setStartDate ] = useState(startValue)
    const [ endDate, setEndDate ] = useState(endValue)
    
    const disabled = endDate < startDate

    const handleSave = () => {
        if (disabled) return

        onChange('date_range', `${startDate}~${endDate}`)
        onClose()
    }

    const handleChangeStart = useCallback((val: Date) => {
        const date = String(moment(val).format('YYYY-MM-DD'))
        setStartDate(date)
    }, [])

    const handleChangeEnd = useCallback((val: Date) => {
        const date = String(moment(val).format('YYYY-MM-DD'))
        setEndDate(date)
    }, [])

    useEffect(() => {
        if (open) {
            const [ startValue, endValue ] = getValue(value)
            setStartDate(startValue)
            setEndDate(endValue)
        }
    }, [open, value])

    return (
        <>
        <div style={{display: 'flex', flexWrap: 'wrap', gap: 5}}>
            <div style={{border: `1px solid ${oc.gray[2]}`}}>
                <CalendarComponent value={startDate} onChange={handleChangeStart}/>
            </div>
            <div style={{border: `1px solid ${oc.gray[2]}`}}>
                <CalendarComponent value={endDate} onChange={handleChangeEnd}/>
            </div>
        </div>
        <DialogActions>
            <Button color='primary' disabled={disabled} onClick={handleSave}>
                {f({id: 'component.Survey.Edit.09.Date.RangeCalendar.button.confirm'})}
            </Button>
            <Button onClick={onClose}>
                {f({id: 'component.Survey.Edit.09.Date.RangeCalendar.button.close'})}
            </Button>
        </DialogActions>
        </>
    )
}

export default memo(RangeCalendarComponent)