/**
 * 달력 시작 날짜
 */
import { useState, useCallback, MouseEvent, memo } from 'react'
import { useIntl } from 'react-intl'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { InputStyled } from 'styled/Input'
import { Props as FormatProps } from './Format'
import { SectionTitle } from './FormatDateDotBox'
import StartCalendar from './StartCalendar'
import { MyPopover } from './Range'

export interface Props extends FormatProps {
    
}

export type OnClose = () => void;

function StartComponent(props: Props) {
    const { value, onChange } = props

    const { formatMessage: f } = useIntl()

    const [ anchorEl, setAnchorEl ] = useState<HTMLInputElement | null>(null)

    const handleOpen = (event: MouseEvent<HTMLInputElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose: OnClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        onChange('date_start', '')
    }

    const open = Boolean(anchorEl)
    const id = open ? 'start-popover' : undefined

    return (
        <>
        <SectionTitle>
            {f({id: 'component.Survey.Edit.09.Date.Start.title'})}
        </SectionTitle>
        <InputStyled
            style={{maxWidth: 240}}
            endAdornment={
                value ? (
                    <IconButton size="small" onClick={handleRemove}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                ) : null
            }
            name="date_start" 
            inputProps={{readOnly: true}}
            value={value}
            placeholder="YYYY-MM-DD"
            onClick={handleOpen}
        />
        <MyPopover title={f({id: 'component.Survey.Edit.09.Date.Start.popoverTitle'})} open={open} id={id} anchorEl={anchorEl} onClose={handleClose}>
            <StartCalendar open={open} value={value} onChange={onChange} onClose={handleClose}/>
        </MyPopover>

        </>
    )
}

export default memo(StartComponent)