import { getMaxlength } from 'policy'
import { withSurveyEditFocus } from 'hoc'
import { Avatar } from '@material-ui/core'
import { IOSSlider } from 'styled/Slider'
import { useIntl } from 'react-intl'
import { InputUnBorderStyled } from 'styled/Input'
import { RemoveIcon, AddIcon } from 'styled/Icon'
import { useStyles, WrapperStyled, TableStyled, AddTableStyled } from 'styled/Survey/Edit/Scale2Example'
import { MaxRows } from '../14/Example'

const Answer = (props) => {
    const { onAnswer, onEdit, states, refAnswers, refScales } = props

    const rows = states.row._answers

    const len = rows.length

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    return (
        <WrapperStyled>
            <TableStyled>
                {
                    rows.map((c, i) => (
                        <div key={c.survey_module_answer_no}>
                            <div>
                                <div><Avatar className={classes.small}>{(i + 1)}</Avatar> </div>
                            </div>
                            <div>
                                <InputUnBorderStyled 
                                    inputRef={ref => refAnswers.current[i] = ref} 
                                    defaultValue={c.answer} 
                                    placeholder={f({id: 'component.Survey.Edit.15.Answer.Input'})}
                                    inputProps={{maxLength: getMaxlength('component.Survey.Edit.15.Answer')}}
                                    name={`answer`} 
                                    onKeyPress= {(e) => e.key === 'Enter' && onAnswer.enterPoint(i)}   
                                    onChange={(e) => onEdit.changeModules(e, '_answers', i)}
                                />
                                <div style={{display: 'none'}}>
                                    <IOSSlider 
                                        ref={ref => refScales.current[i] = ref} 
                                        defaultValue={c.point} 
                                        valueLabelFormat={(value) => `${value}${f({id: 'component.Survey.Edit.15.Answer.point'})}`} 
                                        min={0} 
                                        max={50} 
                                        valueLabelDisplay="on"
                                        name="point"
                                        onChange={onEdit.changeOption}
                                    />
                                </div>
                            </div>
                            <div>
                                <div>{len > 1 && <RemoveIcon onClick={() => onAnswer.remove(c.survey_module_answer_no)}/>}</div>
                            </div>
                        </div>
                    ))
                }
            </TableStyled>
            <MaxRows/>
            {
                rows.length < 10 && (
                    <AddTableStyled>
                        <AddIcon onClick={onAnswer.appendPoint}/>
                    </AddTableStyled>
                )
            }
        </WrapperStyled>
    )
}

export default withSurveyEditFocus('answers')(Answer)