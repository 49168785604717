import { memo } from 'react'
import { useIntl } from 'react-intl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { SelectBaseStyled } from 'styled/Input'

function ZeroNumberComponent(props: any) {
    const { type, states, onOption } = props
    const { row } = states
    const { _option } = row
    const { zero_number } = _option

    const { formatMessage: f } = useIntl()

    return (
        <Select value={zero_number ? 0 : 1}  input={<SelectBaseStyled/>} style={{width: 80}} onChange={(e) => onOption.changeZeroNumber(type, !zero_number)}>
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
        </Select>
    )    
}

export default memo(ZeroNumberComponent)