import { forwardRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { getMaxlength } from 'policy'
import { SurveyEditCommentStyled } from 'styled/Input'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import { withSurveyEditFocus } from 'hoc'

const Rows = forwardRef((props, ref) => {
    const { i, f, answer, onChange } = props

    return (
        <SurveyEditCommentStyled
            notched={true}
            placeholder={f({id: 'component.Survey.Edit.16.Example.Input'})}
            inputProps={{maxLength: getMaxlength('component.Survey.Edit.16.Example')}}
            defaultValue={answer} onClick={(e) => e.target.focus()}
            name="answer"
            onChange={(e) => onChange(e, '_modules', i)}
            inputRef={ref}
        />
    )
})

const Example = () => {
    const { onEdit, states, refExamples } = useContext(PropsContext)

    const rows = states.row._modules

    const { formatMessage: f } = useIntl()

    return (
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
        {
            /* 약관 동의는 로우 한개인데.. 혹시나해서 일단 ...*/
            rows.map((c, i) => (
                <Rows 
                    key={c.survey_module_no} 
                    {...c} 
                    i={i} 
                    f={f} 
                    ref={ref => refExamples.current[i] = ref}
                    onChange={onEdit.changeModules} 
                />
            ))
        }
        </SubTabBox>
    )
}


export default withSurveyEditFocus('examples')(Example)