import { ImageContainer } from 'container'
import { withBreakPoints } from "hoc"

const Component = (props) => {
    const { breakpoints, prevSelected, selected, onAppendUrl, onChange } = props

    const { md, sm, xs } = breakpoints

    const height = xs ? "215px" : sm ? "calc(100vh - 720px)" : md ? "calc(100vh - 820px)" : "calc(100vh - 650px)"

    return (
        <ImageContainer 
            layout="horizontal" 
            height={height}
            onAppendUrl={onAppendUrl} 
            onChange={onChange}
            hideType="close"
            initialSelected={(prevSelected !== selected)} // 선택된값 초기화
        />
    )
}

export default withBreakPoints(Component)