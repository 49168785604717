import { useContext } from 'react'
import styled from 'styled-components'
import { AntTabs, AntTab } from 'styled/AntTab'
import { Typography } from '@material-ui/core'
import PropsContext from 'context/props'

const WrapperStyled = styled.div`
    padding: 0 0 20px 0;
`

function SubTabsComponent(props) {    
    const { subTabs, hideTabs=[] } = props
    const { states, onSubTabs } = useContext(PropsContext)

    return (
        <WrapperStyled>
            <AntTabs value={states.subTabIndex} onChange={onSubTabs.change} aria-label="example-tab">
                {
                    subTabs.map((c, i) => {
                        const style = hideTabs[i] === 1 ? { display: 'none' } : {}
                        return <AntTab key={i} style={style} label={<Typography variant="body2">{c}</Typography>}/>
                    })
                }
            </AntTabs>
        </WrapperStyled>
    )
}

export default SubTabsComponent
