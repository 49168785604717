import { memo } from 'react'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { SurveyEditCommentStyled } from 'styled/Input'

const LabelBox = withStyles({
    root: {
        display: 'flex', 
        alignItems: 'center', 
        gap: 5
    }
})(Box)

function LabelComponent (props) {
    const { onEdit, states, refLabels } = props
    const { left_label, right_label, nps } = states.row._option

    const { formatMessage: f } = useIntl()

    return (
        <LabelBox>
            <div>
                <SurveyEditCommentStyled
                placeholder={nps? f({id: 'component.Survey.Modules.05.labelNps.0'}) : f({id: 'component.Survey.Edit.05.Label.label.0'})}
                defaultValue={left_label}
                inputRef={ref => refLabels.current[0] = ref}
                inputProps={{maxLength: getMaxlength('component.Survey.Edit.05.Label')}}
                name="left_label"
                onChange={onEdit.changeOption} 
                />
            </div>
            <div>
                <SurveyEditCommentStyled
                placeholder={nps? f({id: 'component.Survey.Modules.05.labelNps.1'}) : f({id: 'component.Survey.Edit.05.Label.label.1'})}
                defaultValue={right_label}
                inputRef={ref => refLabels.current[1] = ref}
                inputProps={{maxLength: getMaxlength('component.Survey.Edit.05.Label')}}
                name="right_label"
                onChange={onEdit.changeOption} 
                />
            </div>
        </LabelBox>
    )
}

export default memo(LabelComponent)