import { forwardRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import { SurveyEditCommentStyled } from 'styled/Input'
import { WrapperStyled, BoxStyled } from 'styled/Survey/Edit/MatrixColumn'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import { RemoveIcon, AddIcon } from 'styled/Icon'
import PropsContext from 'context/props'
import { withSurveyEditFocus } from 'hoc'
import OrderDragComponent from '../01/OrderDrag'
import OrderDropComponent from '../01/OrderDrop'

const Rows = forwardRef((props, ref) => {
    const { len, i, f, survey_module_no, column, onEnter, onChange, onRemove } = props
    
    return (
        <BoxStyled>
            <div>
                <SurveyEditCommentStyled 
                    placeholder={f({id: 'component.Survey.Edit.03.Column.Input'})}
                    inputProps={{maxLength: getMaxlength('component.Survey.Edit.03.Column')}}
                    defaultValue={column} onClick={(e) => e.target.focus() } 
                    inputRef={ref}
                    name={`column`}
                    onKeyPress= {(e) => e.key === 'Enter' && onEnter(i)} 
                    onChange={(e) => onChange(e, '_modules', i)}
                />
            </div>
            <div>{len > 1 && <RemoveIcon onClick={() => onRemove(survey_module_no)}/>}</div>
        </BoxStyled>
    )
})


const Component = () => {
    const { onColumn, onEdit, states, refColumns } = useContext(PropsContext)

    const rows = states.row._modules

    const len = rows.length

    const { formatMessage: f } = useIntl()

    return (        
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            <WrapperStyled index={states.subTabIndex}>
                <div>
                <OrderDragComponent id="column-drag" onChange={onColumn.changeOrder}>
                {
                    rows.map((c, i) => (
                        <OrderDropComponent key={c.survey_module_no} id={`column-item-${c.survey_module_no}`} i={i}>
                        <Rows 
                            key={c.survey_module_no} 
                            {...c} 
                            len={len}
                            i={i} 
                            f={f} 
                            ref={ref => refColumns.current[i] = ref}
                            onEnter={onColumn.enter}
                            onChange={onEdit.changeModules} 
                            onRemove={onColumn.remove}
                        />
                        </OrderDropComponent>
                    ))
                }
                </OrderDragComponent>
                </div>
                <div>
                    <AddIcon onClick={onColumn.append}/>
                </div>
            </WrapperStyled>
        </SubTabBox>
    )
}

export default withSurveyEditFocus('columns')(Component)