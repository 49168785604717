import { useContext } from 'react'
import PropsContext from 'context/props'
import { useIntl } from 'react-intl'
import { FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import OptionStyled from 'styled/Survey/Edit/Option'
import { Badge, useStyles } from '../02/Option'
import { CommentOpenComponent, CommnentSetupComponent } from '../01/Option'

export default () => {
    const { onOption, onEdit, states, refComment } = useContext(PropsContext)
    const { openIsComment } = states
    const { required, required_end, random_answer, badge, except_last_random_answer, is_comment_required, is_comment_placeholder} = states.row._option

    const answersCount = states.row._answers.length

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const answers = new Array(answersCount).fill(true)

    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={required} onChange={(e) => onOption.changeRequiredMulti(e, answersCount)} name="required" color="primary"/>
                    } label={f({id:'component.Survey.Edit.17.Option.required'})}/>
                </div>
                
            </div>
            <div className={`sub ${required ? 'open required' : ''}`}>
                <FormControl className={classes.formControl} style={{marginLeft: '25px'}}>
                    <InputLabel id="required-end">{f({id:'component.Survey.Edit.17.Option.required_end'})}</InputLabel>
                    <Select labelId="required-end" value={required_end} onChange={onOption.changeRequiredRank}>
                        <MenuItem value={0}><em>None</em></MenuItem>
                        {
                            answers.map((c, i) => (
                                <MenuItem value={(i+1)} key={(i+1)}>{f({id:'component.Survey.Edit.17.Option.requiredItem'}, {rank: (i+1)})}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <Badge checked={badge} label={f({id:'component.Survey.Edit.17.Option.badge'})} onClick={onOption.changeBadge}/>
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={random_answer} onChange={(e) => onOption.changeRandomAnswer(e)} name="random" color="primary"/>
                    } label={f({id:'component.Survey.Edit.17.Option.randomAnswer'})}/>
                </div>
                
            </div>
            <div className={`sub ${random_answer ? 'open random' : ''}`}>
                <FormControlLabel control={
                    <Checkbox checked={except_last_random_answer} onChange={(e) => onOption.changeExceptLastRandomAnswer(e)} name="exceptLastRandom" color="primary"/>
                } label={f({id:'component.Survey.Edit.17.Option.exceptLastRandom'})}/>
            </div>
            <div className='line'></div>
            <div className='main'>
                <CommentOpenComponent f={f} module_type="17" checked={openIsComment} onChange={onOption.openIsComment}/>
            </div>
            <div className={`sub last ${openIsComment ? 'open isComment' : ''}`}>
                <CommnentSetupComponent
                    f={f}
                    checked={is_comment_required}
                    onChange={onOption.changeIsCommentRequired}
                    defaultValue={is_comment_placeholder}
                    refComment={refComment}
                    onChangeRef={onEdit.changeOption}
                />
            </div>
        </OptionStyled>
    )   
}