import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { useContext } from 'react'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import NpsButtonComponent from '../05/NpsButton'
import ScaleComponent from '../05/Scale'
import ReverseComponent from '../05/Reverse'
import LabelComponent from '../05/Label'
import AnswerComponent from './Answer'
import {
    ScalePaper,
    ScaleTitle,
    ScaleParentGrid,
    ScaleTitleGrid,
    ScaleInputGrid
} from '../05/Component'

function Edit05Component() {
    const { states, refLabels, refAnswers, onExample, onAnswer, onEdit, onOption } = useContext<any>(PropsContext)
    const { row } = states
    const { _option } = row
    const { nps } = _option

    const { formatMessage: f } = useIntl()

    const isNps = nps ? 1 : 0

    return (
        <>
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            <ScalePaper>
                <ScaleParentGrid>
                    <ScaleTitleGrid>
                        <ScaleTitle>Net Promoter Score®</ScaleTitle>
                    </ScaleTitleGrid>
                    <ScaleInputGrid>
                        <NpsButtonComponent states={states} onOption={onOption} />
                    </ScaleInputGrid>
                </ScaleParentGrid>
            </ScalePaper>
            {
                !isNps && (
                    <>
                    <ScalePaper>
                            <ScaleParentGrid>
                                <ScaleTitleGrid>
                                    <ScaleTitle>{f({id: 'component.Survey.Edit.05.Component.subtitle.scale'})}</ScaleTitle>
                                </ScaleTitleGrid>
                                <ScaleInputGrid>
                                    <ScaleComponent type="matrix" states={states} onExample={onExample} onOption={onOption}/>
                                </ScaleInputGrid>
                            </ScaleParentGrid>
                        </ScalePaper>
                        <ScalePaper>
                            <ScaleParentGrid>
                                <ScaleTitleGrid>
                                    <ScaleTitle>{f({id: 'component.Survey.Edit.05.Component.subtitle.sort'})}</ScaleTitle>
                                </ScaleTitleGrid>
                                <ScaleInputGrid>
                                    <ReverseComponent type="matrix" states={states} onOption={onOption} />
                                </ScaleInputGrid>
                            </ScaleParentGrid>
                        </ScalePaper>
                    </>
                )
            }
            <ScalePaper>
                <ScaleParentGrid>
                    <ScaleTitleGrid>
                        <ScaleTitle>{f({id: 'component.Survey.Edit.05.Component.subtitle.label'})}</ScaleTitle>
                    </ScaleTitleGrid>
                    <ScaleInputGrid>
                        <LabelComponent type="matrix" states={states} refLabels={refLabels}  onEdit={onEdit}/>
                    </ScaleInputGrid>
                </ScaleParentGrid>
            </ScalePaper>
        </SubTabBox>
        <AnswerComponent states={states} refAnswers={refAnswers}  onAnswer={onAnswer} onEdit={onEdit}/>
        </>
    )
}

export default Edit05Component