/**
 * 모바일 화면 설명
 */
import { useState, MouseEvent, useEffect } from "react"
import { useIntl } from "react-intl"
import oc from 'open-color'
import { parseHtmlIntl } from "ts-utils"
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from "@material-ui/core/styles"
import withBreakPoints, { BreakPointsProps } from 'hoc/BreakPoints'

interface Props {
    mobile_matrix: boolean;
    breakpoints: BreakPointsProps;
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: 10,
        background: oc.gray[0]
    },
    title: {
        width: '100%',
        color: 'white',
        lineHeight: '130%',
        fontSize: 14,
        fontWeight: 900,
        background: oc.gray[6],
        padding: 10,
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3
    },
    box: {
        borderRadius: 5,
        border: `1px solid ${oc.gray[4]}`,
        width: 330,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%'
        }
    },
    imgBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: oc.gray[1],
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5
    },
    img: {
        borderRadius: 5,
        maxWidth: '100%',
        display: 'block',
        boxShadow: '0px 0px 5px #888',
        maxHeight: 300
    }
}))

function MobileScreenComponent(props: Props) {
    const { mobile_matrix, breakpoints } = props

    const { xs, md } = breakpoints

    const { formatMessage: f } = useIntl()

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null)
    const [ tab, setTab ] = useState(0)

    const classes = useStyles()

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChange = (val: number) => {
        setTab(val)
    }

    const text = [
        f({id: 'component.Survey.Edit.03.Option.mobileMatrixSub.explain.0'}),
        f({id: 'component.Survey.Edit.03.Option.mobileMatrixSub.explain.1'})
    ]

    const img = [
        `${process.env.REACT_APP_IMG}/survey-web/component/Survey/Edit/03/Option/mobile-screen-1.png`,
        `${process.env.REACT_APP_IMG}/survey-web/component/Survey/Edit/03/Option/mobile-screen-2.png?1`,
    ]

    const vertical = md ? 'bottom' : 'top'


    const open = Boolean(anchorEl)
    
    useEffect(() => {
        if (open) setTab(mobile_matrix === false ? 0 : 1)
    }, [open, mobile_matrix])

    if (xs) return null

    return (
        <>
        <Button color="primary" size="small" onClick={handleClick}>
            {f({id: 'component.Survey.Edit.03.MobileScreen.button.detail'})}
        </Button>
        <Popover
            id="mobile-screen-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical,
                horizontal: 'center',
            }}
            getContentAnchorEl={null}
            onClose={handleClose}
        >
            <ButtonGroup variant="contained" disableElevation fullWidth>
                <Button style={{borderBottomLeftRadius: 0}} color={tab === 0 ? 'primary' : 'default'} onClick={() => handleChange(0)}>{f({id: 'component.Survey.Edit.03.Option.mobileMatrixSub.type.0'})}</Button>
                <Button style={{borderBottomRightRadius: 0}} color={tab === 1 ? 'primary' : 'default'} onClick={() => handleChange(1)}>{f({id: 'component.Survey.Edit.03.Option.mobileMatrixSub.type.1'})} </Button>
            </ButtonGroup>
            <div className={classes.container}>
                <div className={classes.box}>
                    <div className={classes.imgBox}>
                        <div style={{padding: 5}}>
                            <img src={img[tab]} alt="mobile-screen" className={classes.img}/>
                        </div>
                        <Typography className={classes.title} component="div">{parseHtmlIntl(text[tab])}</Typography>
                    </div>
                </div>
            </div>
        </Popover>
        </>

    )
}

export default withBreakPoints(MobileScreenComponent)