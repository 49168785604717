import { memo } from 'react'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import { SurveyEditCommentStyled } from 'styled/Input'

function LabelComponent(props: any) {
    const { onEdit, states, refLabels } = props
    const { label } = states.row._option

    const { formatMessage: f } = useIntl()

    return (
        <SurveyEditCommentStyled
            placeholder={f({id: 'component.Survey.Edit.21.Label.label'})}
            defaultValue={label}
            inputRef={ref => refLabels.current[0] = ref}
            inputProps={{maxLength: getMaxlength('component.Survey.Edit.21.Example')}}
            name="label"
            onChange={onEdit.changeOption} 
        />
    )
}

export default memo(LabelComponent)