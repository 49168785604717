import { useContext, useMemo, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { getMaxlength } from 'policy'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { SurveyEditCommentStyled } from 'styled/Input'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'

interface Props {
    index: number;
}

const MyBox = withStyles({
    root: {
        marginBottom: 5
    }
})(Box)

function ConfirmComponent(props: Props) {
    const { index } = props

    const { formatMessage: f } = useIntl()

    const { states, refConfirm, onEdit } = useContext(PropsContext)

    let confirm:undefined | string = undefined
    let currentIndex = 0

    if (states) {
        confirm = states.row._question.confirm
        currentIndex = states.subTabIndex
    }

    const onChange = onEdit.changeConfirmEdit

    return (
        <SubTabBox index={index} currentIndex={currentIndex}>
            <MyBox>
            {
                useMemo(() => {
                    if (typeof confirm === 'undefined') return null
                    
                    return (
                        <SurveyEditCommentStyled 
                            helper={f({id: 'component.Survey.Edit.Confirm.placeholder'})}
                            placeholder={f({id: 'component.Survey.Live.One.button'})}
                            notched={true}
                            inputProps={{maxLength: getMaxlength('component.Survey.Edit.Confirm')}}
                            defaultValue={confirm || ''} onClick={(e: ChangeEvent<HTMLInputElement>) => e.target.focus()}
                            name="confirm"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                            inputRef={refConfirm}
                        />
                    )
                }, [confirm, refConfirm, f, onChange])
            }
            </MyBox>
        </SubTabBox>
    )
}

export default ConfirmComponent