import { useContext } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { getMaxlength } from 'policy'
import styled from 'styled-components'
import { makeStyles, styled as muiStyled } from '@material-ui/core/styles'
import { InputStyled } from 'styled/Input'

const useStyles = makeStyles(theme => ({
    input: {
        width: 150,
        [theme.breakpoints.down('xs')]: {
            width: 120
        }
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
}))

const TableStyled = styled.div`
   
    display: table;

    & > div {
        display: table-row;
    }

    & > div > div {
        display: table-cell;
        vertical-align: middle;
    }

    & > div > div:nth-child(2) {
        padding: 0 10px 20px 10px;
    }
`

export const ContentBox = muiStyled('div')((props) => {
    const { display } = props
    return { 
        display: display ? 'block' : 'none',
        background: 'white',
        padding: 10,
        marginTop: 10,
        borderRadius : 3
    }
})

function BaseComponent() {
    const { states, refBases, onEdit } = useContext(PropsContext)
    const { type, min_length, max_length } = states.row._option

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    return (
        <ContentBox display={Number(type === 'base')}>
            <TableStyled>
                <div>
                    <div>
                        <InputStyled 
                        type="number" 
                        name="min_length" 
                        inputProps={{min: getMaxlength('component.Survey.Edit.09.TypeBase.min'), max: getMaxlength('component.Survey.Edit.09.TypeBase.max')}}
                        defaultValue={min_length} 
                        helper={f({id: 'component.Survey.Edit.09.TypeBase.min'})} 
                        className={classes.input} 
                        inputRef={ref => refBases.current[0] = ref}
                        onChange={onEdit.changeOption}
                        />
                    </div>
                    <div>~</div>
                    <div>
                        <InputStyled 
                        type="number" 
                        name="max_length" 
                        inputProps={{min: getMaxlength('component.Survey.Edit.09.TypeBase.min'), max: getMaxlength('component.Survey.Edit.09.TypeBase.max')}}
                        defaultValue={max_length} 
                        helper={f({id: 'component.Survey.Edit.09.TypeBase.max'}, {max: getMaxlength('component.Survey.Edit.09.TypeBase.max')})} 
                        className={classes.input} 
                        inputRef={ref => refBases.current[1] = ref}
                        onChange={onEdit.changeOption}
                        />
                    </div>
                </div>
            </TableStyled>
        </ContentBox>
    )
}

export default BaseComponent