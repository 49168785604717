import { useContext } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import OptionStyled from 'styled/Survey/Edit/Option'

function Option20Component() {
    const { onOption, states } = useContext<any>(PropsContext)
    const { required, required_address2 } = states.row._option

    const { formatMessage: f } = useIntl()
    
    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={required} onChange={(e) => onOption.changeRequiredAddress(e)} name="required" color="primary"/>
                    } label={f({id:'component.Survey.Edit.10.Option.required'})}/>
                </div>
                
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox disabled={!required} checked={required_address2} onChange={(e) => onOption.changeCheckbox(e)} name="required_address2" color="primary"/>
                    } label={f({id:'component.Survey.Edit.20.Option.required_address2'})}/>
                </div>
                
            </div>
        </OptionStyled>
    )
}

export default Option20Component