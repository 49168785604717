/**
 * 닫기 버튼 (편집중에는 레알 할건지 물어본다)
 */

import { memo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'reducer'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from 'styled/Custom/Tooltip'

interface Props {
    onClose: () => void;
}

function CloseComponent(props: Props) {
    const { onClose } = props

    const { formatMessage: f } = useIntl()

    const states = useSelector((states: RootState) => states.surveyCreateModuleIsEdit)

    const { is } = states

    const handleClose = () => {
         // 제작으로 이동이 아닐경우
         if (is) {
            if (window.confirm(f({id: 'component.Outer.Back.isEditMessage'}))) onClose()
        } else onClose()
    }

    return (
        <Tooltip title={f({id: 'component.Survey.Edit.Tabs.close'})} placement="top">
            <IconButton   onClick={handleClose}>
                <CloseIcon fontSize='small'/>
            </IconButton>
        </Tooltip>
    )
}

export default memo(CloseComponent)