/**
 * 날짜 선택 범위
 */
import { useState, useCallback, MouseEvent, memo } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import IconButton from '@material-ui/core/IconButton'
import Popover, { PopoverProps } from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { InputStyled } from 'styled/Input'
import { Props as FormatProps } from './Format'
import { SectionTitle } from './FormatDateDotBox'
import RangeCalendar from './RangeCalendar'
import { Typography } from '@material-ui/core'

export interface Props extends FormatProps {
    
}

export type OnClose = () => void;

interface MyPopoverProps extends PopoverProps {
    title: string;
}

export const MyPopover = withStyles({
    root: {

    }
})((props: MyPopoverProps) => {
    const { title, children } = props

    return (
        <Popover 
            {...props}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
        >
            <div style={{padding: 5, paddingBottom: 0}}>
                <Typography style={{color: oc.gray[7], fontWeight: 900, paddingLeft: 3}} gutterBottom>{title}</Typography>
                {children}
            </div>
        </Popover>
    )
})

function RangeComponent(props: Props) {
    const { value, onChange } = props

    const { formatMessage: f } = useIntl()

    const [ anchorEl, setAnchorEl ] = useState<HTMLInputElement | null>(null)

    const handleOpen = (event: MouseEvent<HTMLInputElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose: OnClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        onChange('date_range', '')
    }

    const open = Boolean(anchorEl)
    const id = open ? 'range-popover' : undefined

    return (
        <>
        <SectionTitle>{f({id: 'component.Survey.Edit.09.Date.Range.title'})}</SectionTitle>
        <InputStyled
            style={{maxWidth: 240}}
            endAdornment={
                value ? (
                    <IconButton size="small" onClick={handleRemove}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                ) : null
            }
            name="date_range" 
            inputProps={{readOnly: true}}
            value={value ? value.replace('~', ' ~ ') : value}
            placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
            onClick={handleOpen}
        />
        <MyPopover title={f({id: 'component.Survey.Edit.09.Date.Range.title'})} open={open} id={id} anchorEl={anchorEl} onClose={handleClose}>
            <RangeCalendar open={open} value={value} onChange={onChange} onClose={handleClose}/>
        </MyPopover>
        </>
    )
}

export default memo(RangeComponent)