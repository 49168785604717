import { forwardRef, useContext } from 'react'
import PropsContext from 'context/props'
import { Done as DoneIcon } from '@material-ui/icons'
import { Chip, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import { withSurveyEditFocus } from 'hoc'
import { SurveyEditCommentStyled } from 'styled/Input'
import { WrapperStyled, BoxStyled } from 'styled/Survey/Edit/Example'
import { RemoveIcon, AddIcon } from 'styled/Icon'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import OrderDragComponent from '../01/OrderDrag'
import OrderDropComponent from '../01/OrderDrop'

const Rows = forwardRef((props, ref) => {
    const { len, i, f, survey_module_no, answer, is_comment, onEnter, onChange, onChangeComment, onRemove } = props
    
    return (
        <BoxStyled>
            <div>
                <SurveyEditCommentStyled 
                placeholder={f({id: 'component.Survey.Edit.02.Example.Input'})}
                inputProps={{maxLength: getMaxlength('component.Survey.Edit.02.Example')}}
                defaultValue={answer} onClick={(e) => e.target.focus() }
                name={`answer`} 
                onChange={(e) => onChange(e, '_modules', i)}
                onKeyPress= {(e) => e.key === 'Enter' && onEnter(i)}
                inputRef={ref}/>
            </div>
            <div>
                <Chip
                label={<Typography variant="caption">{f({id: 'component.Survey.Edit.02.Example.Chip'})}</Typography>}
                onClick={() => onChangeComment(survey_module_no, is_comment)}
                onDelete={() => {}}
                deleteIcon={<DoneIcon />}
                clickable
                color={is_comment ? "primary" : "default"}
                />
            </div>
            <div>{len > 1 && <RemoveIcon onClick={() => onRemove(survey_module_no)}/>}</div>
        </BoxStyled>
    )
})



const Example = () => {
    const { onExample, onEdit, states, refExamples } = useContext(PropsContext)

    const rows = states.row._modules

    const len = rows.length

    const { formatMessage: f } = useIntl()

    return (
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            <WrapperStyled>
                <div>
                <OrderDragComponent id="module-drag" onChange={onExample.changeOrder}>
                {
                    rows.map((c, i) => (
                        <OrderDropComponent key={c.survey_module_no} id={`item-${c.survey_module_no}`} i={i}>
                            <Rows 
                                key={c.survey_module_no} 
                                {...c} 
                                len={len}
                                i={i} 
                                f={f} 
                                ref={ref => refExamples.current[i] = ref}
                                onEnter={onExample.enter}
                                onChange={onEdit.changeModules} 
                                onChangeComment={onExample.changeIsComment} 
                                onRemove={onExample.removeMulti}
                            />
                        </OrderDropComponent>
                    ))
                }
                </OrderDragComponent>
                </div>
                
                <div>
                    <AddIcon onClick={onExample.append}/>
                </div>
            </WrapperStyled>
        </SubTabBox>
    )
}

export default  withSurveyEditFocus('examples')(Example)