import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { useContext } from 'react'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import {
    ScalePaper,
    ScaleTitle,
    ScaleParentGrid,
    ScaleTitleGrid,
    ScaleInputGrid
} from '../05/Component'
import LabelComponent from './Label'
import ItemsComponent from './Items'

function Edit21Component() {
    const { states, refLabels, onEdit, onExample } = useContext<any>(PropsContext)

    const { formatMessage: f } = useIntl()

    return (
        <>
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            <ScalePaper>
                <ScaleParentGrid>
                    <ScaleTitleGrid xs={3} sm={3} md={3}>
                        <ScaleTitle>
                            {f({id: 'component.Survey.Edit.21.Component.subtitle.phrase'})}
                        </ScaleTitle>
                    </ScaleTitleGrid>
                    <ScaleInputGrid xs={9} sm={9} md={9}>
                        <LabelComponent states={states} refLabels={refLabels} onEdit={onEdit}/>
                    </ScaleInputGrid>
                </ScaleParentGrid>
            </ScalePaper>
            <ScalePaper>
                <ScaleParentGrid>
                    <ScaleTitleGrid xs={3} sm={3} md={3}>
                        <ScaleTitle>
                            {f({id: 'component.Survey.Edit.21.Component.subtitle.choice'})}
                        </ScaleTitle>
                    </ScaleTitleGrid>
                    <ScaleInputGrid xs={9} sm={9} md={9}>
                        <ItemsComponent type="one" states={states} onExample={onExample}/>
                    </ScaleInputGrid>
                </ScaleParentGrid>
            </ScalePaper>
        </SubTabBox>
        </>
    )
}

export default Edit21Component