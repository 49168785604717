import { useContext } from 'react'
import { FormControlLabel, Checkbox, IconButton } from '@material-ui/core'
import { useIntl } from 'react-intl'
import RatingIcon from 'styled/Survey/RatingIcon'
import OptionStyled from 'styled/Survey/Edit/Option'
import PropsContext from 'context/props'
import { CommentOpenComponent, CommnentSetupComponent } from '../01/Option'

export default () => {
    const { onOption, onEdit, states, refComment } = useContext(PropsContext)
    const { openIsComment } = states
    const { required, icon_type, random, except_last_random, is_comment_required, is_comment_placeholder, mobile_one } = states.row._option

    const { formatMessage: f } = useIntl()
    
    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={required} onChange={(e) => onOption.changeRequired(e)} name="required" color="primary"/>
                    } label={f({id:'component.Survey.Edit.12.Option.required'})}/>
                </div>
                
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={random} onChange={(e) => onOption.changeRandom(e)} name="random" color="primary"/>
                    } label={f({id:'component.Survey.Edit.12.Option.random'})}/>
                </div>
                
            </div>
            <div className={`sub ${random ? 'open random' : ''}`}>
                <FormControlLabel control={
                    <Checkbox checked={except_last_random} onChange={(e) => onOption.changeExceptLastRandom(e)} name="exceptLastRandom" color="primary"/>
                } label={f({id:'component.Survey.Edit.12.Option.exceptLastRandom'})}/>
            </div>
            <div className='line'></div>
            <div className='main'>
                <CommentOpenComponent f={f} module_type="12" checked={openIsComment} onChange={onOption.openIsComment}/>
            </div>
            <div className={`sub ${openIsComment ? 'open isComment' : ''}`}>
                <CommnentSetupComponent
                    f={f}
                    checked={is_comment_required}
                    onChange={onOption.changeIsCommentRequired}
                    defaultValue={is_comment_placeholder}
                    refComment={refComment}
                    onChangeRef={onEdit.changeOption}
                />
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={mobile_one} onChange={(e) => onOption.changeMobileOne(e)} name="mobileOne" color="primary"/>
                    } label={f({id:'component.Survey.Edit.12.Option.mobileOne'})}/>
                </div>
                
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox style={{color: 'black'}} checked={true} disabled={false} name="iconType" color="primary"/>
                    } label={f({id:'component.Survey.Edit.12.Option.iconType'})}/>
                </div>
                
            </div>
            <div className={`sub last open iconType`}>
                <IconButton onClick={() => onOption.changeIconType('radio')} color={icon_type === 'radio' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'radio'} icon_type='radio'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('thumbup')} color={icon_type === 'thumbup' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'thumbup'} icon_type='thumbup'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('favorite')} color={icon_type === 'favorite' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'favorite'} icon_type='favorite'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('star')} color={icon_type === 'star' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'star'} icon_type='star'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('notifi')} color={icon_type === 'notifi' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'notifi'} icon_type='notifi'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('emoji')} color={icon_type === 'emoji' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'emoji'} icon_type='emoji'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('cloud')} color={icon_type === 'cloud' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'cloud'} icon_type='cloud'/></IconButton>
            </div>
            
        </OptionStyled>
    )
    
}