/**
 * 범위 설정 달력
 * @returns 
 */
import { memo, useCallback, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import oc from 'open-color'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import CalendarComponent from './Calendar'
import { Props as ParentProps, OnClose } from './Range'

interface Props extends ParentProps {
    open: boolean;
    onClose: OnClose;
}

function StartCalendarComponent(props: Props) {
    const { open, value, onChange, onClose } = props

    const { formatMessage: f } = useIntl()

    const [ newValue, setNewValue ] = useState(value)

    const handleSave = () => {
        onChange('date_start', newValue || moment().format('YYYY-MM-DD'))
        onClose()
    }

    const handleChange = useCallback((val: Date) => {
        const date = String(moment(val).format('YYYY-MM-DD'))
        setNewValue(date)
    }, [])

    useEffect(() => {
        if (open) setNewValue(value)
    }, [open, value])

    return (
        <>
        <div style={{border: `1px solid ${oc.gray[2]}`}}>
            <CalendarComponent value={newValue} onChange={handleChange}/>
        </div>
        <DialogActions>
            <Button color='primary' onClick={handleSave}>
                {f({id: 'component.Survey.Edit.09.Date.RangeCalendar.button.confirm'})}
            </Button>
            <Button onClick={onClose}>
                {f({id: 'component.Survey.Edit.09.Date.RangeCalendar.button.close'})}
            </Button>
        </DialogActions>
        </>
    )
}

export default memo(StartCalendarComponent)