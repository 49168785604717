import { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { IconButton }from '@material-ui/core'
import { Save as SaveIcon, Close as CloseIcon, Replay as ReplayIcon } from '@material-ui/icons'
import Tabs from 'styled/Survey/Edit/Tabs'
import Tooltip from 'styled/Custom/Tooltip'
import ChangeModuleComponent from './ChangeModule'
import CloseComponent from './Close'
import { ScrollContext } from 'container/Survey/Edit/Container'

function TabsComponent(props) {
    const { tabs } = props
    const { edit, states, onTabs, onEdit } = useContext(PropsContext)

    const { id } = useContext(ScrollContext)

    const { row } = states
    const { __typename, _question } = row
    const { question_type, survey_no, survey_question_no } = _question

    const typename = __typename.replace('Module', '')

    const { formatMessage: f } = useIntl()

    const [ zeroTop, setZeroTop ] = useState(true)

    useEffect(() => {
        document.getElementById(id).addEventListener('scroll', function(e) {
            setZeroTop(e.target.scrollTop < 30)
        })
    }, [id])

    return (
        <Tabs tabs={tabs} index={states.tabIndex} borderRadius={zeroTop} onChange={onTabs.change}>
            <Tooltip title={f({id: 'component.Survey.Edit.Tabs.save'})} placement="top">
                <IconButton color="primary"  onClick={onTabs.update[edit.module_type]}>
                    <SaveIcon fontSize='small'/>
                </IconButton>
            </Tooltip>
            <ChangeModuleComponent selectedType={typename} survey_no={survey_no} survey_question_no={survey_question_no} question_type={question_type}/>
            <Tooltip title={f({id: 'component.Survey.Edit.Tabs.init'})} placement="top">
                <IconButton   onClick={onEdit.rollback}>
                    <ReplayIcon fontSize='small'/>
                </IconButton>
            </Tooltip>
            <CloseComponent onClose={onTabs.close}/>
        </Tabs>
    )
}

export default TabsComponent