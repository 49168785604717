import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { useContext } from 'react'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import ReverseComponent from '../05/Reverse'
import ZeroNumberComponent from './ZeroNumber'
import ExampleComponent from './Example'
import {
    ScalePaper,
    ScaleTitle,
    ScaleParentGrid,
    ScaleTitleGrid,
    ScaleInputGrid
} from '../05/Component'

function Edit05Component() {
    const { states, refExamples, onExample, refScales, onEdit, onOption } = useContext<any>(PropsContext)

    const { formatMessage: f } = useIntl()
    
    return (
        <>
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            <ScalePaper>
                <ScaleParentGrid>
                    <ScaleTitleGrid xs={2} sm={2} md={2}>
                        <ScaleTitle>{f({id: 'component.Survey.Edit.07.Component.subtitle.zero_number'})}</ScaleTitle>
                    </ScaleTitleGrid>
                    <ScaleInputGrid xs={10} sm={10} md={10}>
                        <ZeroNumberComponent type="one" states={states} onOption={onOption} />
                    </ScaleInputGrid>
                </ScaleParentGrid>
            </ScalePaper>
            <ScalePaper>
                <ScaleParentGrid>
                    <ScaleTitleGrid xs={2} sm={2} md={2}>
                        <ScaleTitle>{f({id: 'component.Survey.Edit.05.Component.subtitle.sort'})}</ScaleTitle>
                    </ScaleTitleGrid>
                    <ScaleInputGrid xs={10} sm={10} md={10}>
                        <ReverseComponent type="one" states={states} onOption={onOption} />
                    </ScaleInputGrid>
                </ScaleParentGrid>
            </ScalePaper>
            <ScalePaper>
                <ExampleComponent states={states} refExamples={refExamples} refScales={refScales} onExample={onExample} onEdit={onEdit}/>
            </ScalePaper>
        </SubTabBox>
      
        </>
    )
}

export default Edit05Component