import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import oc from 'open-color'
import { Avatar, Typography } from '@material-ui/core'
import { IOSSlider } from 'styled/Slider'
import { InputUnBorderStyled } from 'styled/Input'
import { useStyles, WrapperStyled,TableStyled, AddTableStyled } from 'styled/Survey/Edit/Scale2Example'
import { RemoveIcon, AddIcon } from 'styled/Icon'
import { withSurveyEditFocus } from 'hoc'

export function MaxRows() {
    const { formatMessage: f } = useIntl()

    return (
        <Typography variant="body2" style={{marginTop: 3, marginLeft: 5, color: oc.gray[6]}}>
            {f({id: 'component.Survey.Edit.14.Example.maxRows'}, {max: 10})}
        </Typography>
    )
}

const Example = (props) => {
    const { onExample, onEdit, states, refExamples, refScales } = props

    const rows = states.row._modules

    const len = rows.length

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    return (
        <WrapperStyled>
            <TableStyled>
                
                {
                    rows.map((c, i) => (
                        <div key={c.survey_module_no}>
                            <div>
                                <div><Avatar className={classes.small}>{(i + 1)}</Avatar> </div>
                            </div>
                            <div>
                                <InputUnBorderStyled 
                                    inputRef={ref => refExamples.current[i] = ref} 
                                    defaultValue={c.answer} 
                                    placeholder={f({id: 'component.Survey.Edit.14.Example.Input'})}
                                    inputProps={{maxLength: getMaxlength('component.Survey.Edit.14.Example')}}
                                    name={`answer`} 
                                    onKeyPress= {(e) => e.key === 'Enter' && onExample.enterPoint(i)}   
                                    onChange={(e) => onEdit.changeModules(e, '_modules', i)}
                                />

                                <div style={{display: 'none'}}>
                                    <IOSSlider 
                                        ref={ref => refScales.current[i] = ref} 
                                        defaultValue={c.point} 
                                        valueLabelFormat={(value) => `${value}${f({id: 'component.Survey.Edit.07.Example.point'})}`}
                                        min={0} 
                                        max={50} 
                                        valueLabelDisplay="on"
                                        name="point"
                                        onChange={onEdit.changeOption}
                                        />
                                </div>
                            </div>
                            
                            <div>
                                <div>{len > 1 && <RemoveIcon onClick={() => onExample.remove(c.survey_module_no)}/>}</div>
                            </div>
                        </div>
                    ))
                }
            </TableStyled>
            <MaxRows/>
            {
                rows.length < 10 && (
                    <AddTableStyled>
                        <AddIcon onClick={onExample.appendPoint}/>
                    </AddTableStyled>
                )
            }
        </WrapperStyled>
    )
}


export default withSurveyEditFocus('examples')(Example)