import { useContext } from 'react'
import PropsContext from 'context/props'
import { ContentBox } from '../Base/Component'
import FormatComponent from './Format'
import RangeComponent from './Range'
import StartComponent from './Start'

function DateComponent() {
    const { states, onOption } = useContext<any>(PropsContext)
    const { type, date_format_type, date_range, date_start } = states.row._option

    return (
        <ContentBox display={Number(type === 'date')}>
            <FormatComponent value={date_format_type} onChange={onOption.change09Date}/>
            <div style={{marginTop: 20}}></div>
            <StartComponent value={date_start} onChange={onOption.change09Date}/>
            <div style={{marginTop: 20}}></div>
            <RangeComponent value={date_range} onChange={onOption.change09Date}/>
        </ContentBox>
    )
}

export default DateComponent