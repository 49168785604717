import {useContext} from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import OptionStyled from 'styled/Survey/Edit/Option'
import { Badge, useStyles } from '../02/Option'
import MobileScreenComponent from '../03/MobileScreen'
import { MatrixMobileRadioGroup } from '../03/Option'
import { CommentOpenComponent, CommnentSetupComponent } from '../01/Option'

export default ()  => {

    const { onOption, onEdit, states, refComment } = useContext(PropsContext)
    const { 
        required, 
        required_start, 
        required_end, 
        badge,
        random_column, 
        except_last_random_column, 
        random_answer, 
        except_last_random_answer, 
        is_comment, 
        is_comment_required, 
        is_comment_placeholder,
        mobile_matrix
    } = states.row._option


    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const answers = new Array(states.row._answers.length).fill(true)
    
    return (
        <OptionStyled>
           <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={required} onChange={(e) => onOption.changeRequiredMulti(e, answers.length)} name="required" color="primary"/>
                    } label={f({id:'component.Survey.Edit.04.Option.required'})}/>
                </div>
                
            </div>
            <div className={`sub ${required ? 'open required' : ''}`}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="required-start">{f({id:'component.Survey.Edit.04.Option.required_start'})}</InputLabel>
                    <Select labelId="required-start" value={required_start} onChange={onOption.changeRequiredMultiStart}>
                        <MenuItem value={0}><em>None</em></MenuItem>
                        {
                            answers.map((c, i) => <MenuItem value={(i+1)} key={(i+1)}>{(i+1)}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} style={{marginLeft: '25px'}}>
                    <InputLabel id="required-end">{f({id:'component.Survey.Edit.04.Option.required_end'})}</InputLabel>
                    <Select labelId="required-end" value={required_end} onChange={onOption.changeRequiredMultiEnd}>
                        <MenuItem value={0}><em>None</em></MenuItem>
                        {
                            answers.map((c, i) => <MenuItem value={(i+1)} key={(i+1)}>{(i+1)}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <Badge checked={badge} label={f({id:'component.Survey.Edit.04.Option.badge'})} onClick={onOption.changeBadge}/>
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={random_column} onChange={(e) => onOption.changeRandomColumn(e)} color="primary"/>
                    } label={f({id:'component.Survey.Edit.04.Option.randomColumn'})}/>
                </div>
                
            </div>
            <div className={`sub ${random_column ? 'open random' : ''}`}>
                <FormControlLabel control={
                    <Checkbox checked={except_last_random_column} onChange={(e) => onOption.changeExceptLastRandomColumn(e)} color="primary"/>
                } label={f({id:'component.Survey.Edit.04.Option.exceptLastRandomColumn'})}/>
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={random_answer} onChange={(e) => onOption.changeRandomAnswer(e)} color="primary"/>
                    } label={f({id:'component.Survey.Edit.04.Option.randomAnswer'})}/>
                </div>
                
            </div>
            <div className={`sub ${random_answer ? 'open random' : ''}`}>
                <FormControlLabel control={
                    <Checkbox checked={except_last_random_answer} onChange={(e) => onOption.changeExceptLastRandomAnswer(e)} color="primary"/>
                } label={f({id:'component.Survey.Edit.04.Option.exceptLastRandomAnswer'})}/>
            </div>
            <div className='line'></div>

            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox style={{color: 'black'}} checked={true} disabled={false} name="checkbox_mobile_matrix" color="primary" />
                    } label={f({id:'component.Survey.Edit.03.Option.mobileMatrix'})}/>
                    <MobileScreenComponent mobile_matrix={mobile_matrix} />
                </div>
                
            </div>
            <div className="sub open isComment">
                <MatrixMobileRadioGroup mobile_matrix={mobile_matrix} onChange={onOption.changeMobileMatrix}/>
            </div>
            <div className='line'></div>


            <div className='main'>
                <CommentOpenComponent f={f} module_type="04" checked={is_comment} onChange={onOption.changeIsComment}/>
            </div>
            <div className={`sub last ${is_comment ? 'open isComment' : ''}`}>
                <CommnentSetupComponent
                    f={f}
                    checked={is_comment_required}
                    onChange={onOption.changeIsCommentRequired}
                    defaultValue={is_comment_placeholder}
                    refComment={refComment}
                    onChangeRef={onEdit.changeOption}
                />
            </div>
        </OptionStyled>
    )
    
}