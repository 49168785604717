import { forwardRef }  from 'react'
import { Button, Dialog, AppBar, Toolbar, IconButton, Typography, Slide }from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Close as CloseIcon } from '@material-ui/icons'
import oc from 'open-color-js'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        backgroundColor: oc.gray9
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}))

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default (props) => {
    const { module_type, number, open, onClose, onUpdate, children } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles()
    
    const title = f({id: `component.Survey.Edit.Modal.title.${module_type}`}, {number})

    return (
        <Dialog fullScreen disableEscapeKeyDown open={open} onClose={onClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>{title}</Typography>
                <Button autoFocus color="inherit" onClick={onUpdate}>{f({id: 'component.Survey.Edit.Modal.save'})}</Button>
                
            </Toolbar>
            </AppBar>
           
            {children}
        </Dialog>
    )
}