import {useContext} from 'react'
import { FormControlLabel, Checkbox, Radio, RadioGroup, Typography, TextField } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import OptionStyled from 'styled/Survey/Edit/Option'
import PropsContext from 'context/props'

export const CommentOpenComponent = (props) => {
    const { f, module_type, checked, onChange } = props

    return (
        <div>
            <FormControlLabel control={
                <Checkbox checked={checked} onChange={onChange} name="isComment" color="primary"/>
            } label={f({id:`component.Survey.Edit.${module_type}.Option.isComment`})}/>
        </div>
    )
}
export const CommnentSetupComponent = (props) => {
    const { f, checked, onChange, defaultValue, refComment, onChangeRef } = props
    return (
        <div style={{display: 'flex', alignItems: 'center', gap: 5}}>      
            <FormControlLabel control={
                    <Checkbox checked={checked} onChange={onChange} name="isCommentRequired" color="primary"/>
                } label={<Typography variant="body1" style={{whiteSpace: 'nowrap'}}>{f({id: 'component.Survey.Edit.01.Option.CommentSetup.required'})}</Typography>}/>

            <TextField
                defaultValue={defaultValue}
                inputProps={{maxLength: getMaxlength('component.Survey.Edit.01.Option.comment')}}
                placeholder={f({id: 'component.Survey.Edit.01.Option.CommentSetup.placeholder'})}
                inputRef={refComment}
                name="is_comment_placeholder"
                size="small"
                margin='dense'
                fullWidth
                onChange={onChangeRef}
            />
        </div>
    )
}

export default () => {
    const { onOption, onEdit, states, refComment } = useContext(PropsContext)
    const { openIsComment } = states
    const { required, random, except_last_random, layout, is_comment_required, is_comment_placeholder} = states.row._option

    const { formatMessage: f } = useIntl()

    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={required} onChange={(e) => onOption.changeRequired(e)} name="required" color="primary"/>
                    } label={f({id:'component.Survey.Edit.01.Option.required'})}/>
                </div>
                
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={random} onChange={(e) => onOption.changeRandom(e)} name="random" color="primary"/>
                    } label={f({id:'component.Survey.Edit.01.Option.random'})}/>
                </div>
                
            </div>
            <div className={`sub ${random ? 'open random' : ''}`}>
                <FormControlLabel control={
                    <Checkbox checked={except_last_random} onChange={(e) => onOption.changeExceptLastRandom(e)} name="exceptLastRandom" color="primary"/>
                } label={f({id:'component.Survey.Edit.01.Option.exceptLastRandom'})}/>
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={layout > 1} onChange={(e) => onOption.changeLayout(e)} name="layout" color="primary"/>
                    } label={f({id:'component.Survey.Edit.01.Option.layout'})}/>
                </div>
                
            </div>
            <div className={`sub ${layout > 1 ? 'open layout' : ''}`}>
                <RadioGroup row name="layoutNum" value={Number(layout)} onChange={onOption.changeLayoutNum}>
                    <FormControlLabel value={2} control={<Radio color="primary"/>} label={f({id:'component.Survey.Edit.01.Option.layoutNum.0'})}/>
                    <FormControlLabel value={3} control={<Radio color="primary"/>} label={f({id:'component.Survey.Edit.01.Option.layoutNum.1'})}/>
                    <FormControlLabel value={4} control={<Radio color="primary"/>} label={f({id:'component.Survey.Edit.01.Option.layoutNum.2'})}/>
                    <FormControlLabel value={5} control={<Radio color="primary"/>} label={f({id:'component.Survey.Edit.01.Option.layoutNum.3'})}/>
                </RadioGroup>
            </div>
            <div className='line'></div>
            <div className='main'>
                <CommentOpenComponent f={f} module_type="01" checked={openIsComment} onChange={onOption.openIsComment}/>
            </div>
            <div className={`sub last ${openIsComment ? 'open isComment' : ''}`}>
                <CommnentSetupComponent
                    f={f}
                    checked={is_comment_required}
                    onChange={onOption.changeIsCommentRequired}
                    defaultValue={is_comment_placeholder}
                    refComment={refComment}
                    onChangeRef={onEdit.changeOption}
                />
            </div>
        </OptionStyled>
    )
}