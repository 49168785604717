import { memo } from 'react'
import oc from 'open-color'
import { useIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

function ReverseComponent(props: any) {
    const { type, states, onOption } = props
    const { row } = states
    const { _option } = row
    const { reverse } = _option

    const { formatMessage: f } = useIntl()

    return (
        <Button 
            startIcon={
                reverse ? <CheckBoxIcon color='primary'/> : <CheckBoxOutlineBlankIcon color='action'/>
            }
            onClick={(e) => onOption.changeReverse(type, !reverse)} 
        >
        {f({id:'component.Survey.Edit.05.Option.reverse'})}
    </Button>
    )    
}

export default memo(ReverseComponent)