
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { getMimePicture } from 'utils/survey'
import { Typography, Paper, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { 
    PictureInPictureAlt as PictureInPictureAltIcon, 
    VerticalSplit as VerticalSplitIcon, 
    ChromeReaderMode as ChromeReaderModeIcon 
} from '@material-ui/icons'
import Video from 'styled/Survey/Live/Video'
import Youtube from 'styled/Survey/Live/Youtube'

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'inline-block',
      padding: 5
    },
    icon3: {
        transform: 'rotate(180deg)'
    },
    typography: {
        fontWeight: 700
    },
    noMaxWidth: {
        maxWidth: 'none'
    },
    preview: (props) => {
        const { img_src } = props

        return {
            width: 60,
            height: 60,
            padding: '3px',
            backgroundImage: `url(${img_src})`,
            backgroundSize: '100% 100%'
        }
    }
}))

const BoxStyled = styled.div`
    display: flex;
    flex-direction: row;

    & > div + div {
        margin-left: 3em;
    }
    
`

export default (props) => {
    const { titleStack='', titleFloat='', titleSplit='', img_src, img_name, position, on } = props
  
    const classes = useStyles({ img_src })
    const { formatMessage: f } = useIntl()

    const mime = getMimePicture(img_src)

    return (
        <>
        {
            mime === 'file' ? (
                <>
                <Typography gutterBottom variant="subtitle2" className={classes.typography}>
                    {f({id: 'component.Survey.Edit.ImageAttach.Position.title.0'})}
                </Typography>
                {img_name}
                </>
            ) : (
                <BoxStyled>
                <div>
                    <Typography gutterBottom variant="subtitle2" className={classes.typography}>{f({id: 'component.Survey.Edit.ImageAttach.Position.title.0'})}</Typography>
                    <Paper className={classes.preview} elevation={2}>
                        {
                            mime === 'video' ? <Video src={img_src} autoplay={1} mute={1} control={0}/> :
                            mime === 'youtube' && <Youtube src={img_src} autoplay={1} mute={1} control={0}/>
                        }
                    </Paper>
                </div>
                <div>
                    {
                        mime !== 'file' && (
                            <>
                            <Typography gutterBottom variant="subtitle2" className={classes.typography}>{f({id: 'component.Survey.Edit.ImageAttach.Position.title.1'})}</Typography>
                            <Paper className={classes.root} elevation={2}>
                            <ToggleButtonGroup exclusive value={position} onChange={on.changePosition}>
                                <Tooltip arrow classes={{ tooltip: classes.noMaxWidth }} title={titleStack}>
                                    <ToggleButton edge="start" value='stack'>
                                        <PictureInPictureAltIcon color={position === 'stack' ? 'primary' : 'inherit'}/>
                                    </ToggleButton>
                                </Tooltip>
                                <Tooltip arrow classes={{ tooltip: classes.noMaxWidth }} title={titleFloat}>
                                    <ToggleButton edge="start" value='float'>
                                        <VerticalSplitIcon color={position === 'float' ? 'primary' : 'inherit'}/>
                                    </ToggleButton>
                                </Tooltip>
                                {
                                    mime === 'youtube' ? (
                                        <ToggleButton edge="start" value='split' disabled={true}>
                                            <ChromeReaderModeIcon color={position === 'split' ? 'primary' : 'inherit'} className={classes.icon3}/>
                                        </ToggleButton>
                                    ) : (
                                        <Tooltip arrow classes={{ tooltip: classes.noMaxWidth }} title={titleSplit}>
                                            <ToggleButton edge="start" value='split'>
                                                <ChromeReaderModeIcon color={position === 'split' ? 'primary' : 'inherit'} className={classes.icon3}/>
                                            </ToggleButton>
                                        </Tooltip>
                                    )
                                }
                                
                            </ToggleButtonGroup>
                            </Paper>
                            </>
                        )
                    }
                </div>
                </BoxStyled>
            )
        }
        </>
    )
}