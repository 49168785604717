/**
 * 날짜형식 변경
 */
import { memo } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { SelectStyled } from 'styled/Input'
import { OnChange } from './FormatDateDotBox'
import { Date } from './Format'

interface Props {
    date: Date;
    onChange: OnChange<Date>;
}

function FormatDateComponent(props: Props) {
    const { date, onChange } = props

    return (
        <Select 
            name="date"
            value={date} 
            style={{ width: 140, background: 'white' }} 
            input={<SelectStyled fontSize={13} padding="8px 12px 8px 8px"/>} onChange={onChange}
        >
            <MenuItem value="YYYYMMDD">YYYYMMDD</MenuItem>
            <MenuItem value="MMDDYYYY">MMDDYYYY</MenuItem>
        </Select>
    )
}

export default memo(FormatDateComponent)