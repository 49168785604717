import { useContext } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import oc from 'open-color'
import styled from 'styled-components'
import { numberFormat } from 'utils'
import { getMaxlength } from 'policy'
import { Checkbox, FormControlLabel, FormControl, FormGroup, Button, ButtonGroup, Box } from '@material-ui/core'
import { InputStyled } from 'styled/Input'
import { ContentBox } from '../Base/Component'

const ScaleStyled = styled.div`
    display: grid;
    grid-template-columns: minmax(2em, 1fr) minmax(2em, 1fr) 1em minmax(2em, 1fr);
    grid-template-rows: 1fr auto;
    gap: .5em;

    & > div.row1 {
        grid-area: row1;
    }

    & > div.row2 {
        grid-area: row2;
    }

    & > div.row3 {
        grid-area: row3;
    }

    & > div.row4 {
        grid-area: row4;
    }

    & > div.row5 {
        grid-area: row5;
        margin-top: 8px;
    }

    & > div.row6 {
        grid-area: row6;
        display: none;
    }

    grid-template-areas: 
        "row1 row2 row3 row4"
        "row5 row5 row5 row5";

    @media only screen and (max-width: 600px) {

        & > div.row6 {
            display: block;
        }
        
        grid-template-columns: minmax(2em, 1fr) 1em minmax(2em, 1fr);
        grid-template-rows: 1fr 1fr auto;

        grid-template-areas: 
            "row1 row6 row6"
            "row2 row3 row4"
            "row5 row5 row5";
    };

    & > div:nth-child(3) {
        justify-self: center;
        padding-top: .7em;
    }
`

function NumberComponent() {
    const { states, onTypeNumber, refNumbers, onEdit } = useContext(PropsContext)
    const { type, min_number, max_number, unit, number_format, unit_position, minus, decimald } = states.row._option

    const { formatMessage: f } = useIntl()

    return (
        <ContentBox display={Number(type === 'number')}>
            <ScaleStyled>
                <div className="row1">
                    <InputStyled
                    endAdornment={
                        <ButtonGroup size="small" variant="text" disableElevation color="primary">
                            <Button 
                                color={!unit_position ? 'primary' : 'default'} 
                                onClick={() => onTypeNumber.changeUnitPosition(false)}
                            >
                                {f({id: 'component.Survey.Edit.09.TypeNumber.unit_position.0'})}
                            </Button>
                            <Button 
                                color={unit_position ? 'primary' : 'default'} 
                                onClick={() => onTypeNumber.changeUnitPosition(true)}
                            >
                                {f({id: 'component.Survey.Edit.09.TypeNumber.unit_position.1'})}
                            </Button>
                        </ButtonGroup>
                    }
                    name="unit" 
                    inputProps={{maxLength: getMaxlength('component.Survey.Edit.09.TypeNumber.unit')}}
                    inputRef={ref => refNumbers.current[2] = ref}
                    defaultValue={unit} 
                    helper={f({id: 'component.Survey.Edit.09.TypeNumber.unit'})}
                    onChange={onEdit.changeOption}
                    />
                </div>
                <div className="row2">
                    <InputStyled 
                    type="number" 
                    name="min_number" 
                    inputProps={{min: getMaxlength('component.Survey.Edit.09.TypeNumber.min'), max: getMaxlength('component.Survey.Edit.09.TypeNumber.max')}}
                    inputRef={ref => refNumbers.current[0] = ref}
                    defaultValue={min_number} 
                    helper={f({id: 'component.Survey.Edit.09.TypeNumber.min'}, {min: numberFormat(getMaxlength('component.Survey.Edit.09.TypeNumber.min'))})}
                    onChange={onEdit.changeOption}
                    />
                </div>
                <div className="row3">~</div>
                <div className="row4">
                    <InputStyled 
                    type="number" 
                    name="max_number" 
                    inputProps={{min: getMaxlength('component.Survey.Edit.09.TypeNumber.min'), max: getMaxlength('component.Survey.Edit.09.TypeNumber.max')}}
                    inputRef={ref => refNumbers.current[1] = ref}
                    defaultValue={max_number} 
                    helper={f({id: 'component.Survey.Edit.09.TypeNumber.max'}, {max: numberFormat(getMaxlength('component.Survey.Edit.09.TypeNumber.max'))})} 
                    onChange={onEdit.changeOption}
                    />
                </div>
                
                <div className="row5">
                    <Box style={{background: oc.gray[2], borderRadius: 5, padding: '5px 10px'}}>
                        <FormControl component="fieldset">
                            <FormGroup row>
                                <FormControlLabel 
                                    control={<Checkbox size="small" name="decimald" checked={decimald} onChange={onTypeNumber.changeCheckbox} />} 
                                    label={f({id: 'component.Survey.Edit.09.TypeNumber.decimald'})}
                                />
                                <FormControlLabel 
                                    control={<Checkbox size="small" name="minus" checked={minus} onChange={onTypeNumber.changeCheckbox} />} 
                                    label={f({id: 'component.Survey.Edit.09.TypeNumber.minus'})}
                                />
                                <FormControlLabel 
                                    control={<Checkbox size="small" name="number_format" checked={number_format} onChange={onTypeNumber.changeCheckbox} />} 
                                    label={f({id: 'component.Survey.Edit.09.TypeNumber.numberFormat'})}
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </div>
                <div className="row6"></div>
            </ScaleStyled>
        </ContentBox>
    )
}

export default NumberComponent