import { memo } from 'react'
import oc from 'open-color'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'

export const NpsButton = withStyles({
    root: {
        background: 'white', 
        color: oc.gray[7], 
        border: '1px solid #ced4da',
        paddingLeft: 15,
        paddingRight: 15
    }
})(Button)

function NpsButtonComponent(props: any) {
    const { states, onOption } = props
    
    const { row } = states
    const { __typename, _option } = row

    const { nps } = _option

    let type: null | string = null
    if (__typename === "Module_05") {
        type = 'one'
    } else if (__typename === "Module_06") {
        type = 'matrix'
    }

    if (!type) return null

    return (
        <Switch edge="start" checked={nps} onChange={() => onOption.changeNps(type, !nps)} name="nps" color='primary'/>
    )
}


export default memo(NpsButtonComponent)