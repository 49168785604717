import { useContext } from 'react'
import { FormControlLabel, Checkbox, IconButton } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import { SurveyEditCommentStyled } from 'styled/Input'
import RatingIcon from 'styled/Survey/RatingIcon'
import OptionStyled from 'styled/Survey/Edit/Option'
import PropsContext from 'context/props'

export default () => {
    const { onOption, onEdit, states, refAgreeRequiredMessage } = useContext(PropsContext)
    const { required_message, icon_type } = states.row._option

    const { formatMessage: f } = useIntl()
    
    return (
        <OptionStyled>
             <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox style={{color: 'black'}} checked={true} disabled={false} name="iconType" color="primary"/>
                    } label={f({id: 'component.Survey.Edit.16.Option.requiredMessage.label'})}/>
                </div>
                
            </div>
            <div className={`sub open iconType`} style={{paddingTop: 20, paddingBottom: 20}}>
                <SurveyEditCommentStyled 
                    placeholder={f({id: 'component.Survey.Edit.16.Option.requiredMessage.placeholder'})} 
                    defaultValue={required_message}
                    inputRef={refAgreeRequiredMessage} 
                    inputProps={{maxLength: getMaxlength('component.Survey.Edit.16.requiredMessage')}}
                    name="required_message"
                    onChange={onEdit.changeOption}
                />
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox style={{color: 'black'}} checked={true} disabled={false} name="iconType" color="primary"/>
                    } label={f({id:'component.Survey.Edit.15.Option.iconType'})}/>
                </div>
                
            </div>
            <div className={`sub last open iconType`}>
                <IconButton onClick={() => onOption.changeIconType('assign')} color={icon_type === 'assign' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'assign'} icon_type='assign'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('notifi')} color={icon_type === 'notifi' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'notifi'} icon_type='notifi'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('emoji')} color={icon_type === 'emoji' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'emoji'} icon_type='emoji'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('thumbup')} color={icon_type === 'thumbup' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'thumbup'} icon_type='thumbup'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('cloudDone')} color={icon_type === 'cloudDone' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'cloudDone'} icon_type='cloudDone'/></IconButton>
            </div>
        </OptionStyled>
    )   
}