/**
 * 날짜형식 변경
 */
import { memo } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import RemoveIcon from '@material-ui/icons/Remove'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import { SelectStyled } from 'styled/Input'
import { OnChange } from './FormatDateDotBox'
import { Dot } from './Format'

interface Props {
    dot: Dot;
    onChange: OnChange<Dot>;
}

function FormatDateComponent(props: Props) {
    const { dot, onChange } = props

    return (
        <Select 
            name="dot"
            value={dot} 
            style={{ width: 90, background: 'white', marginLeft: 10 }} 
            input={<SelectStyled fontSize={13} padding="5px 12px 1px 8px"/>} onChange={onChange}
            renderValue={(value: string) => {

                switch(value) {
                    case '-':
                        return <RemoveIcon fontSize='small' style={{marginTop: 3, width: 15}}/>
                    case '/': 
                        return <RemoveIcon fontSize='small' style={{marginTop: 3, width: 15, transform: 'rotate(-0.15turn)'}}/>
                    case '.': 
                        return <Brightness1Icon fontSize='small' style={{marginTop: 3, width: 5}}/>
                    default: 
                        return value || ''
                }
            
            }}
        >
            <MenuItem value="-"><RemoveIcon fontSize='small' style={{width: 15}}/></MenuItem>
            <MenuItem value="/"><RemoveIcon fontSize='small' style={{width: 15, transform: 'rotate(-0.15turn)'}}/></MenuItem>
            <MenuItem value="."><Brightness1Icon fontSize='small' style={{width: 5}}/></MenuItem>
        </Select>
    )
}

export default memo(FormatDateComponent)