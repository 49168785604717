import { useEffect, useState } from 'react'
import { ArrowUpward as ArrowUpwardIcon }  from '@material-ui/icons'
import styled  from 'styled-components'
import { Link } from 'react-scroll'
import { useIntl } from 'react-intl'
import Tooltip from 'styled/Custom/Tooltip'
import IconButton from 'styled/Custom/IconButton'

const WrapperStyled = styled.div`
    position: sticky;
    bottom: 0;
    right: 0;
    float: right;
    display: ${props => props.open ? 'block' : 'none'};
    z-index: 10;

    @media only screen and (max-width: 600px) {
        bottom: -1em;
    }
`

function TopComponent(props) {
    const { scrolls } = props
    
    const { formatMessage: f } = useIntl()

    const [ open, setOpen ] = useState(false)

    useEffect(() => {
       document.getElementById(scrolls.id).addEventListener('scroll', function(e) {
            setOpen(e.target.scrollTop > 368)
        });
    }, [scrolls.id])

    return (
        <WrapperStyled open={open}>
            <Tooltip title={f({id: 'component.Survey.Edit.Top.title'})}>
                <Link to={scrolls.to} smooth={true} duration={100} containerId={scrolls.id}>
                    <IconButton>
                        <ArrowUpwardIcon/>
                    </IconButton>
                </Link>
            </Tooltip>
        </WrapperStyled>
    )
}

export default TopComponent