import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { useContext } from 'react'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import IconComponent from '../14/Icon'
import AnswerComponent from './Answer'
import {
    ScalePaper,
    ScaleTitle,
    ScaleParentGrid,
    ScaleTitleGrid,
    ScaleInputGrid
} from '../05/Component'

function Edit14Component() {
    const { states, refAnswers, refScales, onAnswer, onEdit, onOption } = useContext<any>(PropsContext)

    const { formatMessage: f } = useIntl()

    return (
        <>
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            <ScalePaper>
                <ScaleParentGrid>
                    <ScaleTitleGrid xs={2} sm={2} md={2}>
                        <ScaleTitle>{f({id: 'component.Survey.Edit.14.Component.subtitle.icon'})}</ScaleTitle>
                    </ScaleTitleGrid>
                    <ScaleInputGrid xs={10} sm={10} md={10}>
                        <IconComponent states={states} onOption={onOption}/>
                    </ScaleInputGrid>
                </ScaleParentGrid>
            </ScalePaper>
            <ScalePaper>
                <AnswerComponent states={states} refAnswers={refAnswers}  refScales={refScales}  onAnswer={onAnswer} onEdit={onEdit}/>
            </ScalePaper>
        </SubTabBox>

        </>
    )
}

export default Edit14Component