import { useContext } from 'react'
import PropsContext from 'context/props'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import ExampleComponent from '../12/Example'

function Component() {
    const { onAnswer, onEdit, states, refAnswers } = useContext(PropsContext)
    const { showImageUpload, row } = states
    const { _answers } = row

    return (
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            <ExampleComponent
                type="_answers"
                minRows={2}
                showImageUploadNo={showImageUpload}
                rows={_answers}
                refComments={refAnswers}
                onAppend={() => onAnswer.append({ src: '', is_comment: false })}
                onRemove={onAnswer.removeMulti}
                onChangeOrder={onAnswer.changeOrder}
                onChangeComment={onAnswer.changeIsComment}
                onChangeImage={onAnswer.changeImage}
                onEnter={onAnswer.enterImg}
                onShowImageUpload={onAnswer.showImageUpload}
                onEdit={onEdit}
            />
        </SubTabBox>
    )
}

export default Component