import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { SelectBaseStyled } from 'styled/Input'
import { ScaleTitle } from './Component'

const MyBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 10
    }
})(Box)

function ScaleComponent(props: any) {
    const { type, states, onExample, onOption } = props

    const { row } = states
    const { _option } = row

    const rows = type === 'one' ? row._modules : row._answers

    const { zero_number } = _option

    const guideLen = zero_number ? rows.length - 1 : rows.length
    
    return (
        <MyBox>
            <Select value={zero_number ? 0 : 1}  input={<SelectBaseStyled/>} style={{width: 80}} onChange={(e) => onOption.changeZeroNumber(type, !zero_number)}>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
            </Select>
            <ScaleTitle>to</ScaleTitle>
            <Select value={guideLen} variant="outlined" input={<SelectBaseStyled/>} style={{width: 80}} onChange={(e) => onExample.changeScale(type, e)}>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
            </Select>
        </MyBox>
    )
}

export default ScaleComponent