import styled from 'styled-components'

const WrapperStyled = styled.div`
    display: ${({show}) => show === 'contents' ? 'block' : 'none'};
    position: relative;
`
export default (props) => {
    const { show, children } = props
 
    return (
        <WrapperStyled show={show}>
            {children}
        </WrapperStyled>
    )
}