import { memo, useState, useRef, ChangeEvent, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import oc from 'open-color'
import sortArray from 'sort-array'
import { parseHtmlIntl } from 'ts-utils'
import { getMaxlength } from 'policy'
import { _21Module, _22Answer } from 'gql/survey_question_modules'
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import SortComponent from './Sort'

export type OnChangeSort = () => void

const MyInputBase = withStyles(theme => ({
    root: {
        border: `1px solid ${oc.gray[4]}`,
        borderRadius: 5,
        padding: 10
    },
    error: {
        border: `1px solid ${oc.red[4]}`,
    }
}))(InputBase)

// modules의 answer값만 담는다.
const getWords = (rows: _21Module[] |  _22Answer[] ) => {
    return rows.map(c => c.answer).filter(c => c).join("\n")
}

const maxOneEnterLen = getMaxlength('component.Survey.Edit.21.Enter.one')
const maxMultiEnterLen = getMaxlength('component.Survey.Edit.21.Enter.multi')
const maxWordLen = getMaxlength('component.Survey.Edit.21.Example')

export const getError = (kind: boolean, words: string) => {
    const wordArray = words.replace(/\r\n/g, "\n").split("\n").filter(c => c)
    const len = wordArray.length

    const maxEnterLen = !kind ? maxOneEnterLen : maxMultiEnterLen

    const wordErrorArray = wordArray.reduce((acc: number[], c: string, i: number) => {
        if (c.trim().length > maxWordLen) {
            acc.push((i+1))
        }

        return acc
    }, [])

    return {
        enterError: [len - maxEnterLen > 0, len],
        wordError: [wordErrorArray.length > 0, wordErrorArray]
    }
}

let timer: ReturnType<typeof setTimeout> 

const ascending = (a: string , b: string) => {          
    const newA = a.toString().toLowerCase()
    const newB = b.toString().toLowerCase()
    
    return newA.localeCompare(newB) 
} 

function ItemsComponent(props: any) {
    const { type, states, onExample } = props

    const { _option, _question } = states.row
    const { survey_no } =_question
    const { kind } = _option

    const ItemRef = useRef<InputBaseProps>(null)

    const { row } = states
   
    const rows = row[type === 'one' ? '_modules' : '_answers']
    

    const { formatMessage: f } = useIntl()

    const originWords = getWords(rows)

    const [ words, setWords ] = useState<string>(originWords)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timer)

        setWords(e.target.value)

        timer = setTimeout(() => {
            onExample.appendDropdown(type, e.target.value)
        }, 300)
    }

    const handleChangeSort: OnChangeSort = useCallback(() => {
        if (!words) return
        const array = String(words).replace(/\r\n/g, /\n/).split('\n').filter(c => c)
    
        array.sort(ascending)

        const newWords = array.join('\n')
        setWords(newWords)
        onExample.appendDropdown(type, newWords)
        
    }, [words, type, onExample])

    const { enterError, wordError } = getError(kind, words)

    const error = Boolean(enterError[0] === true || wordError[0] === true)

    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <>
        <Typography variant='body2' style={{color: oc.gray[7], marginTop: 5, marginBottom: 5}} component="div">
            <ul style={{marginLeft: 15, lineHeight: '140%', fontSize: 13, marginBottom: 3}}>
                <li>{f({id: 'component.Survey.Edit.21.Items.lists.0'})}</li>
                <li>{f({id: 'component.Survey.Edit.21.Items.lists.1'}, {one: maxOneEnterLen, multi: maxMultiEnterLen})}</li>
                <li>{f({id: 'component.Survey.Edit.21.Items.lists.2'}, { limit: maxWordLen})}</li>
            </ul>
        </Typography>
        <div style={{ position: 'relative' }}>
            <MyInputBase
                inputRef={ItemRef}
                multiline
                autoFocus
                minRows={10}
                maxRows={10}
                fullWidth
                error={error}
                value={words}
                onChange={handleChange}
            />
            <SortComponent survey_no={survey_no} onChange={handleChangeSort} />
        </div>
        
        <div style={{display: 'flex', justifyContent: 'space-between', gap: 10, marginRight: 1, marginTop: 3, marginBottom: 5}}>
            <Typography variant='body2' color='secondary' style={{opacity: error ? 1 : 0, fontSize: 12, lineHeight: '130%'}}>
                {
                    enterError[0] === true ? (
                        parseHtmlIntl(f({id: 'component.Survey.Edit.21.Items.error.0'}, { limit: !kind ? maxOneEnterLen : maxMultiEnterLen , count: enterError[1]}))
                    ) : wordError[0] === true && (
                        
                        parseHtmlIntl(f({id: 'component.Survey.Edit.21.Items.error.1'}, { limit: maxWordLen, count: wordError[1].join(', ')}))
                        
                    ) 
                }
            </Typography>
            <Typography variant='caption' style={{color: oc.gray[7]}}>
                {f({id: 'component.Survey.Edit.21.Items.choiceLength'}, {count: rows.length})}  
            </Typography>
        </div>
        </>
     
    )
}

export default memo(ItemsComponent)