import { forwardRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import PropsContext from 'context/props'
import { Chip, Typography } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import { WrapperStyled, BoxStyled } from 'styled/Survey/Edit/Example'
import { SurveyEditCommentStyled } from 'styled/Input'
import { RemoveIcon, AddIcon } from 'styled/Icon'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import { withSurveyEditFocus } from 'hoc'
import OrderDragComponent from '../01/OrderDrag'
import OrderDropComponent from '../01/OrderDrop'

const Rows = forwardRef((props, ref) => {
    const { len, i, f, survey_module_answer_no, answer, is_comment, onEnter, onChange, onChangeComment, onRemove } = props
    
    return (
        <BoxStyled>
            <div>
                <SurveyEditCommentStyled
                notched={true}
                placeholder={f({id: 'component.Survey.Edit.17.Answer.Input'})}
                inputProps={{maxLength: getMaxlength('component.Survey.Edit.17.Example')}}
                defaultValue={answer} onClick={(e) => e.target.focus()}
                name={`answer`} 
                onChange={(e) => onChange(e, '_answers', i)}
                onKeyPress= {(e) => e.key === 'Enter' && onEnter(i, { is_comment: false })}
                inputRef={ref}
                />
            </div>
            <div>
                <Chip
                label={<Typography variant="caption">{f({id: 'component.Survey.Edit.17.Answer.Chip'})}</Typography>}
                onClick={() => onChangeComment(survey_module_answer_no, is_comment)}
                onDelete={() => {}}
                deleteIcon={<DoneIcon />}
                clickable
                color={is_comment ? "primary" : "default"}
                />
            </div>
            <div>{len > 2 && <RemoveIcon onClick={() => onRemove(survey_module_answer_no)}/>}</div>
        </BoxStyled>
    )
})

const Component = () => {
    const { onAnswer, onEdit, states, refAnswers } = useContext(PropsContext)

    const { formatMessage: f } = useIntl()

    const rows = states.row._answers

    const len = rows.length

    return (
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            <WrapperStyled>
                <div>
                <OrderDragComponent id="answer-drag" onChange={onAnswer.changeOrder}>
                {
                    rows.map((c, i) => (
                        <OrderDropComponent key={c.survey_module_answer_no} id={`answer-item-${c.survey_module_answer_no}`} i={i}>
                        <Rows 
                            key={c.survey_module_answer_no} 
                            {...c} 
                            len={len}
                            i={i} 
                            f={f} 
                            ref={ref => refAnswers.current[i] = ref} 
                            onEnter={onAnswer.enter}
                            onChange={onEdit.changeModules}
                            onChangeComment={onAnswer.changeIsComment}    
                            onRemove={onAnswer.removeMulti}
                        />
                        </OrderDropComponent>
                    ))
                }
                </OrderDragComponent>
                </div>
                
                <div>
                    <AddIcon onClick={() => onAnswer.append({ is_comment: false })}/>
                </div>
            </WrapperStyled>
        </SubTabBox>
    )
}

export default withSurveyEditFocus('answers')(Component)