import { useContext }  from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { parseHtmlIntl } from 'ts-utils'
import OptionStyled from 'styled/Survey/Edit/Option'
import PropsContext from 'context/props'

export default () => {
    const { onOption, states } = useContext(PropsContext)
    const { required, badge } = states.row._option

    const { formatMessage: f } = useIntl()
    
    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={required} onChange={(e) => onOption.changeRequired(e)} name="required" color="primary"/>
                    } label={f({id:'component.Survey.Edit.09.Option.required'})}/>
                </div>
                
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={badge} onChange={() => onOption.changeBadge(!badge)} name="required" color="primary"/>
                    } label={parseHtmlIntl(f({id:'component.Survey.Edit.09.Option.badge'}))}/>
                </div>
                
            </div>
        </OptionStyled>
    )
    
}