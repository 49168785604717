import { forwardRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import PropsContext from 'context/props'
import { SurveyEditCommentStyled } from 'styled/Input'
import { WrapperStyled, BoxStyled } from 'styled/Survey/Edit/MatrixAnswer'
import { RemoveIcon, AddIcon } from 'styled/Icon'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import { withSurveyEditFocus } from 'hoc'
import OrderDragComponent from '../01/OrderDrag'
import OrderDropComponent from '../01/OrderDrop'

const Rows = forwardRef((props, ref) => {
    const { len, i, f, survey_module_answer_no, answer, onEnter, onChange, onRemove } = props
    
    return (
        <BoxStyled>
            <div>
                <SurveyEditCommentStyled 
                    placeholder={f({id: 'component.Survey.Edit.04.Answer.Input'})}
                    inputProps={{maxLength: getMaxlength('component.Survey.Edit.04.Answer')}}
                    defaultValue={answer} onClick={(e) => e.target.focus() } 
                    inputRef={ref}
                    name={`answer`} 
                    onKeyPress= {(e) => e.key === 'Enter' && onEnter(i)}
                    onChange={(e) => onChange(e, '_answers', i)}
                />
            </div>
            <div>{len > 1 && <RemoveIcon onClick={() => onRemove(survey_module_answer_no)}/>}</div>
        </BoxStyled>
    )
})


const Component = () => {
    const { onAnswer, onEdit, states, refAnswers } = useContext(PropsContext)

    const rows = states.row._answers

    const len = rows.length

    const { formatMessage: f } = useIntl()

    return (       
        <SubTabBox index={1} currentIndex={states.subTabIndex}> 
            <WrapperStyled>
                <div>
                <OrderDragComponent id="answer-drag" onChange={onAnswer.changeOrder}>
                {
                    rows.map((c, i) => (
                        <OrderDropComponent key={c.survey_module_answer_no} id={`answer-item-${c.survey_module_answer_no}`} i={i}>
                        <Rows 
                            key={c.survey_module_answer_no} 
                            {...c} 
                            len={len}
                            i={i} 
                            f={f} 
                            ref={ref => refAnswers.current[i] = ref}
                            onChange={onEdit.changeModules}   
                            onEnter={onAnswer.enter}
                            onRemove={onAnswer.removeMulti}
                        />
                        </OrderDropComponent>
                    ))

                }
                </OrderDragComponent>
                </div>
                <div>
                    <AddIcon onClick={() => onAnswer.append()}/>
                </div>
            </WrapperStyled>
        </SubTabBox>
    )
}

export default withSurveyEditFocus('answers')(Component)