// 보기 순서 변경
import { ReactNode } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

interface Props {
    id: string;
    onChange: (a: any) => void;
    children: ReactNode;
}

function OrderComponent(props: Props) {
    const { id, onChange, children } = props

    return (
        <DragDropContext onDragEnd={onChange} >
            <Droppable droppableId={id}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef}  {...provided.droppableProps}> 
                    <div>{children}</div>
                    {provided.placeholder}    
                </div>
            )}
            </Droppable>
        </DragDropContext>
    )
}

export default OrderComponent

