import { useContext } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem, IconButton } from '@material-ui/core'
import RatingIcon from 'styled/Survey/RatingIcon'
import OptionStyled from 'styled/Survey/Edit/Option'
import { Badge, useStyles } from '../02/Option'
import { CommentOpenComponent, CommnentSetupComponent } from '../01/Option'

export default () => {
    const { onOption, onEdit, states, refComment } = useContext(PropsContext)
    const { openIsComment } = states
    const { 
        required, 
        icon_type, 
        required_start, 
        required_end, 
        badge, 
        random, 
        except_last_random, 
        is_comment_required, 
        is_comment_placeholder,
        mobile_one
    } = states.row._option

    const modulesCount = states.row._modules.length

    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const modules = new Array(modulesCount).fill(true)

    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={required} onChange={(e) => onOption.changeRequiredMulti(e)} name="required" color="primary"/>
                    } label={f({id:'component.Survey.Edit.13.Option.required'})}/>
                </div>
                
            </div>
            <div className={`sub ${required ? 'open required' : ''}`}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="required-start">{f({id:'component.Survey.Edit.13.Option.required_start'})}</InputLabel>
                    <Select labelId="required-start" value={required_start} onChange={onOption.changeRequiredMultiStart}>
                        <MenuItem value={0}><em>None</em></MenuItem>
                        {
                            modules.map((c, i) => <MenuItem value={(i+1)} key={(i+1)}>{(i+1)}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} style={{marginLeft: '25px'}}>
                    <InputLabel id="required-end">{f({id:'component.Survey.Edit.13.Option.required_end'})}</InputLabel>
                    <Select labelId="required-end" value={required_end} onChange={onOption.changeRequiredMultiEnd}>
                        <MenuItem value={0}><em>None</em></MenuItem>
                        {
                            modules.map((c, i) => <MenuItem value={(i+1)} key={(i+1)}>{(i+1)}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <Badge checked={badge} label={f({id:'component.Survey.Edit.13.Option.badge'})} onClick={onOption.changeBadge}/>
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={random} onChange={(e) => onOption.changeRandom(e)} name="random" color="primary"/>
                    } label={f({id:'component.Survey.Edit.13.Option.random'})}/>
                </div>
                
            </div>
            <div className={`sub ${random ? 'open random' : ''}`}>
                <FormControlLabel control={
                    <Checkbox checked={except_last_random} onChange={(e) => onOption.changeExceptLastRandom(e)} name="exceptLastRandom" color="primary"/>
                } label={f({id:'component.Survey.Edit.13.Option.exceptLastRandom'})}/>
            </div>
            <div className='line'></div>
            <div className='main'>
                <CommentOpenComponent f={f} module_type="13" checked={openIsComment} onChange={onOption.openIsComment}/>
            </div>
            <div className={`sub ${openIsComment ? 'open isComment' : ''}`}>
                <CommnentSetupComponent
                    f={f}
                    checked={is_comment_required}
                    onChange={onOption.changeIsCommentRequired}
                    defaultValue={is_comment_placeholder}
                    refComment={refComment}
                    onChangeRef={onEdit.changeOption}
                />
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={mobile_one} onChange={(e) => onOption.changeMobileOne(e)} name="mobileOne" color="primary"/>
                    } label={f({id:'component.Survey.Edit.12.Option.mobileOne'})}/>
                </div>
                
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox style={{color: 'black'}} checked={true} disabled={false} name="iconType" color="primary"/>
                    } label={f({id:'component.Survey.Edit.13.Option.iconType'})}/>
                </div>
                
            </div>
            <div className={`sub last open iconType`}>
                <IconButton onClick={() => onOption.changeIconType('checkbox')} color={icon_type === 'checkbox' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'checkbox'} icon_type='checkbox'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('thumbup')} color={icon_type === 'thumbup' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'thumbup'} icon_type='thumbup'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('favorite')} color={icon_type === 'favorite' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'favorite'} icon_type='favorite'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('star')} color={icon_type === 'star' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'star'} icon_type='star'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('notifi')} color={icon_type === 'notifi' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'notifi'} icon_type='notifi'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('emoji')} color={icon_type === 'emoji' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'emoji'} icon_type='emoji'/></IconButton>
                <IconButton onClick={() => onOption.changeIconType('cloudDone')} color={icon_type === 'cloudDone' ? 'primary' : 'default'}><RatingIcon selected={icon_type === 'cloudDone'} icon_type='cloudDone'/></IconButton>
            </div>
        </OptionStyled>
    )   
}