/**
 * Calendar 컴포넌트
 */
import { memo } from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { Moment } from 'moment'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils  from '@date-io/date-fns'
import { getLocale } from 'ts-utils'
 
interface Props {
    value: string | Moment;
    onChange: (val: Date) => void;
    intl: IntlShape;
}

function RangeCalendarComponent(props: Props) {
    const { value, onChange, intl } = props

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(intl.locale)}>
            <DatePicker 
                autoOk
                variant="static" 
                orientation="portrait"
                value={value} 
                onChange={onChange}
            />         
        </MuiPickersUtilsProvider>           
    )
}
 
export default memo(injectIntl(RangeCalendarComponent))