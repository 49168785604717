import { useState, useRef, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { handleDisabledRightClick } from 'ts-utils'
import { getMaxlength } from 'policy'
import { Chip, IconButton, Avatar, Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { 
    Done as DoneIcon, 
    PhotoLibrary as PhotoLibraryIcon, 
    ArrowBack as ArrowBackIcon  
} from '@material-ui/icons'
import { SurveyEditCommentStyled } from 'styled/Input'
import { ContainerStyled, WrapperStyled, BoxStyled, ImageUploadStyled } from 'styled/Survey/Edit/ImageExample'
import { RemoveIcon, AddIcon } from 'styled/Icon'
import { withSurveyEditFocus, withPrevState } from 'hoc'
import ImageBoxComponent from './ImageBox'
import OrderDragComponent from '../01/OrderDrag'
import OrderDropComponent from '../01/OrderDrop'

const CloseButton = withStyles(theme => ({
    root: {
        marginTop: 10
    }
}))(props => <Button 
        fullWidth 
        variant="contained" 
        disableElevation 
        color="primary" {...props}
        startIcon={<ArrowBackIcon/>}
/>)

let Rows = (props) => {
    const { 
        len,
        minRows,
        i, 
        f, 
        id, 
        type,
        answer, 
        src, 
        is_comment,
        showImageUploadNo,
        refDivs,
        refComments,
        onEnter, 
        onChange, 
        onChangeSelected,
        onChangeComment, 
        onRemove, 
        onShowImageUpload,
    } = props
    
    const InsertImage = () => !src ? <IconButton size="small"><PhotoLibraryIcon/></IconButton> :  <Avatar src={src} alt="" onContextMenu={handleDisabledRightClick}/>
    
    return (
        <>
        <BoxStyled>
            <div
                ref={ref => refDivs.current[i] = ref}
                style={{cursor: 'pointer'}} 
                onClick={async () => {
                    await onShowImageUpload(id)
                    onChangeSelected(i)
                }} 
            >
                {
                    showImageUploadNo === id && (
                        <div className="check">
                            <DoneIcon color="secondary" style={{width: 12, height: 12, color: 'white'}}/>
                        </div>
                    )
                }
                <InsertImage/>
            </div>
            <div>
                <SurveyEditCommentStyled
                notched={true}
                placeholder={f({id: 'component.Survey.Edit.12.Example.Input'})}
                inputProps={{maxLength: getMaxlength('component.Survey.Edit.12.Example')}}
                defaultValue={answer} onClick={(e) => e.target.focus() } 
                inputRef={ref => refComments.current[i] = ref}
                name={`answer`} 
                onKeyPress= {(e) => e.key === 'Enter' && onEnter(i)}
                onChange={(e) => onChange(e, type, i)}
                />
            </div>
            <div>
                <Chip
                label={<Typography variant="caption">{f({id: 'component.Survey.Edit.12.Example.Chip'})}</Typography>}
                onClick={() => onChangeComment(id, is_comment)}
                onDelete={() => {}}
                deleteIcon={<DoneIcon />}
                clickable
                color={is_comment ? "primary" : "default"}
                />
            </div>
            <div>{len > minRows && <RemoveIcon onClick={() => onRemove(id)}/>}</div>
        </BoxStyled>
        
        </>
    )
}

/*Rows = React.memo(Rows, (prev, next) => {
    // 보기텍스트, 이미지, 코멘트 여부, 이미지 편집선택 값이 변한게 없다면 재랜더링 안한다 return true가 재랜더링 안한다는거다
    // 이미지 선택은 id showImageUploadNo 를 비교해 두개다 관여안된 보기는 재랜더링 안한다.
    return  prev.answer === next.answer && 
            prev.src === next.src && 
            prev.is_comment === next.is_comment && 
            !(prev.id === prev.showImageUploadNo || prev.id === next.showImageUploadNo)
})*/


const Example = (props) => {
    const { 
        usePrevState, 
        minRows=1,
        rows, 
        type, 
        showImageUploadNo, 
        refComments, 
        onAppend, 
        onRemove, 
        onChangeOrder,
        onChangeComment,
        onChangeImage,
        onEnter,
        onShowImageUpload,
        onEdit  
    }  = props

    // 보기리스트의 div
    const refDivs = useRef([])

    // 보기 WrapperStyled 
    const refWrapper = useRef()

    // 보기 Container 
    const refContainer = useRef()

    const len = rows.length

    const { formatMessage: f } = useIntl()

    // 이미지 선택한 보기의 포커스 주기위해 i 값 지정한다
    const [ selected, setSelected ] = useState('')

    // rows 이전길이값 저장
    const prevRowsLength = usePrevState(rows.length)

    // selected 이전값
    const prevSelected = usePrevState(selected)

    const changeSelected = useCallback((_selected) => {
        setSelected(_selected)
    }, [])

    // 이미지 버튼을 클릭했다면 이미지 첨부파일 관리함 레이어를 띄워준다.
    const isShowImageUploadNo = showImageUploadNo ? true : false

    useEffect(() => {
        if (selected === 0 || selected) {
            refComments.current[selected].focus()
            refComments.current[selected].blur()
        }
    }, [refComments, selected])

    // 이미지 선택창 닫으면 포커스도 해제
    useEffect(() => {
        if (!isShowImageUploadNo) setSelected('')
    }, [isShowImageUploadNo])


    useEffect(() => {
        if (rows.length !== prevRowsLength) refWrapper.current.scrollTo({top: 10000, behavior: 'smooth'});
    }, [rows, prevRowsLength, refWrapper])

    return (
        <ContainerStyled imageView={isShowImageUploadNo}>
            <div  ref={ref => refContainer.current = ref}>
                <WrapperStyled ref={ref => refWrapper.current = ref} imageView={isShowImageUploadNo}>
                    <div>
                    <OrderDragComponent id="module-drag" onChange={onChangeOrder}>
                    {
                        rows.map((c, i) => {
                            const id = type === '_modules' ? c.survey_module_no : c.survey_module_answer_no
                            return (
                                <OrderDropComponent key={id} id={`item-${id}`} i={i}>
                                <Rows 
                                    key={id} 
                                    showImageUploadNo={showImageUploadNo} 
                                    {...c} 
                                    len={len}
                                    minRows={minRows}
                                    type={type}
                                    id={id}
                                    i={i} 
                                    f={f}
                                    refDivs={refDivs} 
                                    refComments={refComments} 
                                    onChange={onEdit.changeModules} 
                                    onChangeSelected={changeSelected}
                                    onChangeComment={onChangeComment}
                                    onChangeImage={onChangeImage}
                                    onRemove={onRemove}
                                    onEnter={onEnter}
                                    onShowImageUpload={onShowImageUpload}
                                />
                                </OrderDropComponent>
                            )
                        })
                    }
                    </OrderDragComponent>
                    </div>
                    <div>
                        <AddIcon onClick={onAppend}/>
                    </div>
                </WrapperStyled>
                {
                    isShowImageUploadNo && (
                        <>
                        <ImageUploadStyled className={'show'} marginTop='10px' marginBottom={0}>
                            <ImageBoxComponent 
                                prevSelected={prevSelected}
                                selected={selected}
                                onAppendUrl={onChangeImage(showImageUploadNo)}
                                onChange={onChangeImage(showImageUploadNo)}
                            />
                        </ImageUploadStyled>
                        <CloseButton onClick={() => onShowImageUpload('')}>
                            <Typography variant="button">{f({id: 'component.Survey.Edit.12.Example.close'})}</Typography>
                        </CloseButton>
                        </>
                    )
                }
            </div>
        </ContainerStyled>
    )
}


export default withSurveyEditFocus('examples')(withPrevState(Example))