import { memo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { GET_SURVEY_ANALYSIS_IS_RESPONSE } from 'gql/survey_analysis_users'
import { SHOW as ALERTSNACKBARSHOW } from 'reducer/alertSnackbar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { OnChangeSort } from './Items'


interface Props {
    survey_no: number;
    onChange: OnChangeSort
}

let timer: ReturnType<typeof setTimeout> 

function SortComponent(props: Props) {
    const { survey_no, onChange } =props

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const [ disabled, setDisabed ] = useState(false)

    const [ getIsResponse ] = useLazyQuery(GET_SURVEY_ANALYSIS_IS_RESPONSE, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.surveyAnalysisIsResponse) dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'component.Survey.Edit.21.Sort.getIsResponse.is'}), variant: 'error' })
            else onChange()
            clearTimeout(timer)
            setTimeout(() => {
                setDisabed(false)
            }, 1000)

        },
        onError: () => {
            dispatch({ type: ALERTSNACKBARSHOW, message: f({id: 'component.Survey.Edit.21.Sort.getIsResponse.error'}), variant: 'error' })
            clearTimeout(timer)
            setTimeout(() => {
                setDisabed(false)
            }, 1000)
        }
    })

    const handleChange = () => {
        setDisabed(true)
        getIsResponse({
            variables: {
                survey_no
            }
        })
    }

    return (
        <Button 
            size="small" 
            variant="contained" 
            disableElevation 
            disabled={disabled}
            startIcon={disabled ? <CircularProgress size={20} /> : null}
            style={{ position: 'absolute', right: 1, bottom: 1, borderTopRightRadius: 0 , borderBottomLeftRadius: 0 }}
            onClick={handleChange}
        >
            {f({id: 'component.Survey.Edit.21.Sort.button.sort'})}
        </Button>
    )
}

export default memo(SortComponent)