import {useContext} from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import { FormControlLabel, Checkbox, Radio, RadioGroup, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import OptionStyled from 'styled/Survey/Edit/Option'
import { CommentOpenComponent, CommnentSetupComponent } from '../01/Option'

export const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120
    }
}))

export function Badge(props) {
    const { checked, label, onClick } = props
    return (
        <Button 
            variant="outlined" 
            size="small" 
            color={checked ? 'primary' : 'default'} 
            startIcon={checked ? <DoneIcon/> : <CheckBoxOutlineBlankIcon/>}
            onClick={() => onClick(!checked)}
        >
            {label}
        </Button>
    )
}

export default () => {
    
    const { onOption, onEdit, states, refComment } = useContext(PropsContext)
    const { openIsComment } = states
    const { required, required_start, required_end, random, except_last_random, layout, is_comment_required, is_comment_placeholder, badge } = states.row._option

    const modulesCount = states.row._modules.length
    
    const classes = useStyles()

    const { formatMessage: f } = useIntl()

    const modules = new Array(modulesCount).fill(true)

    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={required} onChange={(e) => onOption.changeRequiredMulti(e, modulesCount)} name="required" color="primary"/>
                    } label={f({id:'component.Survey.Edit.02.Option.required'})}/>
                </div>
                
            </div>
            <div className={`sub ${required ? 'open required' : ''}`}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="required-start">{f({id:'component.Survey.Edit.02.Option.required_start'})}</InputLabel>
                    <Select labelId="required-start" value={required_start} onChange={onOption.changeRequiredMultiStart}>
                        <MenuItem value={0}><em>None</em></MenuItem>
                        {
                            modules.map((c, i) => <MenuItem value={(i+1)} key={(i+1)}>{(i+1)}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id="required-end">{f({id:'component.Survey.Edit.02.Option.required_end'})}</InputLabel>
                    <Select labelId="required-end" value={required_end} onChange={onOption.changeRequiredMultiEnd}>
                        <MenuItem value={0}><em>None</em></MenuItem>
                        {
                            modules.map((c, i) => <MenuItem value={(i+1)} key={(i+1)}>{(i+1)}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <Badge checked={badge} label={f({id:'component.Survey.Edit.02.Option.badge'})} onClick={onOption.changeBadge}/>
            </div>
            
            <div className='line'></div>
  
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={random} onChange={(e) => onOption.changeRandom(e)} name="random" color="primary"/>
                    } label={f({id:'component.Survey.Edit.02.Option.random'})}/>
                </div>
                
            </div>
            <div className={`sub ${random ? 'open random' : ''}`}>
                <FormControlLabel control={
                    <Checkbox checked={except_last_random} onChange={(e) => onOption.changeExceptLastRandom(e)} name="exceptLastRandom" color="primary"/>
                } label={f({id:'component.Survey.Edit.02.Option.exceptLastRandom'})}/>
            </div>
            <div className='line'></div>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={layout > 1} onChange={(e) => onOption.changeLayout(e)} name="layout" color="primary"/>
                    } label={f({id:'component.Survey.Edit.02.Option.layout'})}/>
                </div>
                
            </div>
            <div className={`sub ${layout > 1 ? 'open layout' : ''}`}>
                <RadioGroup row name="layoutNum" value={Number(layout)} onChange={onOption.changeLayoutNum}>
                    <FormControlLabel value={2} control={<Radio color="primary"/>} label={f({id:'component.Survey.Edit.02.Option.layoutNum.0'})}/>
                    <FormControlLabel value={3} control={<Radio color="primary"/>} label={f({id:'component.Survey.Edit.02.Option.layoutNum.1'})}/>
                    <FormControlLabel value={4} control={<Radio color="primary"/>} label={f({id:'component.Survey.Edit.02.Option.layoutNum.2'})}/>
                    <FormControlLabel value={5} control={<Radio color="primary"/>} label={f({id:'component.Survey.Edit.02.Option.layoutNum.3'})}/>
                </RadioGroup>
            </div>
            <div className='line'></div>

            <div className='main'>
                <CommentOpenComponent f={f} module_type="02" checked={openIsComment} onChange={onOption.openIsComment}/>
            </div>
            <div className={`sub last ${openIsComment ? 'open isComment' : ''}`}>
                <CommnentSetupComponent
                    f={f}
                    checked={is_comment_required}
                    onChange={onOption.changeIsCommentRequired}
                    defaultValue={is_comment_placeholder}
                    refComment={refComment}
                    onChangeRef={onEdit.changeOption}
                />
            </div>
        </OptionStyled>
    )  
}