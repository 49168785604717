// 날짜형식
import { memo } from 'react'
import FormatDateDotBoxComponent from './FormatDateDotBox'

export interface Props {
    value: string;
    onChange: (name: string, value: string) => void;
}

export type Date = string;
export type Dot = string;

// 포멧 구분자 타입
const getFormatDotType = (value: string): [Date, Dot] => {
    if (!value) return ['YYYYMMDD', '-']
    
    let dot = '-'
    if (value.search(/\//) >= 0) dot = '/'
    else if (value.search(/\./) >= 0) dot = '.'

    const format = value.split(dot)

    return [ `${format[0]}${format[1]}${format[2]}`, dot]
}

function FormatComponent(props: Props) {
    const { value, onChange } = props

    const [ date, dot ] = getFormatDotType(value)

    return (
        <FormatDateDotBoxComponent date={date} dot={dot} onChange={onChange}/>
    )
}

export default memo(FormatComponent)