import { useContext } from 'react'
import PropsContext from 'context/props'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import ExampleComponent from './Example'

function Component() {
    const { onExample, onEdit, states, refExamples } = useContext(PropsContext)
    const { showImageUpload, row } = states
    const { _modules } = row

    return (
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            <ExampleComponent
                type="_modules"
                showImageUploadNo={showImageUpload}
                rows={_modules}
                refComments={refExamples}
                onAppend={onExample.append}
                onRemove={onExample.removeImage}
                onChangeOrder={onExample.changeOrder}
                onChangeComment={onExample.changeIsComment}
                onChangeImage={onExample.changeImage}
                onEnter={onExample.enter}
                onShowImageUpload={onExample.showImageUpload}
                onEdit={onEdit}
            />
        </SubTabBox>
    )
}

export default Component