import { memo } from 'react'
import { Avatar } from '@material-ui/core'
import { IOSSlider } from 'styled/Slider'
import { useIntl } from 'react-intl'
import { RemoveIcon } from 'styled/Icon'
import { useStyles } from 'styled/Survey/Edit/Scale1Example'


// nps 추가 버튼 가능 여부
export const showAddButton = (len, zero_number) => {
    if (zero_number && len > 10) return false
    if (!zero_number && len > 9) return false

    return true
}

const Example = (props) => {
    const { onExample, onEdit, states, refExamples } = props

    const { _modules } = states.row
    const rows = _modules
  
    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    // 해당값이 없으면 오류가 나서 삭제안하고 화면을 가린다
    return (
        <div style={{display: 'none'}}>
             {
                rows.map((c, i) => (
                    <div key={c.survey_module_no}>
                        <div>
                            <div><Avatar className={classes.small}>{(c.indexs)}</Avatar> </div>
                        </div>
                        <div>
                            <IOSSlider 
                                ref={ref => refExamples.current[i] = ref} 
                                defaultValue={c.point} 
                                valueLabelFormat={(value) => `${value}${f({id: 'component.Survey.Edit.05.Example.point'})}`} 
                                min={0} 
                                max={50} 
                                valueLabelDisplay="on"
                                name="point"
                                onChange={onEdit.changeOption}
                                
                            />
                        </div>
                        <div>{i > 1 && <RemoveIcon onClick={() => onExample.removePoint(c.survey_module_no)}/>}</div>
                    </div>
                ))
            }
        </div>
    )
}


export default memo(Example)