import { useContext } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { useIntl } from 'react-intl'
import OptionStyled from 'styled/Survey/Edit/Option'
import PropsContext from 'context/props'
import { CommentOpenComponent, CommnentSetupComponent } from '../01/Option'

export default () => {
    const { onOption, onEdit, states, refComment } = useContext(PropsContext)
    const { required, random_column, except_last_random_column, is_comment, is_comment_required, is_comment_placeholder} = states.row._option

    const { formatMessage: f } = useIntl()
    
    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={required} onChange={(e) => onOption.changeRequired(e)} name="required" color="primary"/>
                    } label={f({id:'component.Survey.Edit.06.Option.required'})}/>
                </div>
                
            </div>
            <div className='line'></div>

            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={random_column} onChange={(e) => onOption.changeRandomColumn(e)} color="primary"/>
                    } label={f({id:'component.Survey.Edit.06.Option.randomColumn'})}/>
                </div>
                
            </div>
            <div className={`sub ${random_column ? 'open random' : ''}`}>
                <FormControlLabel control={
                    <Checkbox checked={except_last_random_column} onChange={(e) => onOption.changeExceptLastRandomColumn(e)} color="primary"/>
                } label={f({id:'component.Survey.Edit.06.Option.exceptLastRandomColumn'})}/>
            </div>
            <div className='line'></div>

            <div className='main'>
                <CommentOpenComponent f={f} module_type="06" checked={is_comment} onChange={onOption.changeIsComment}/>
            </div>
            <div className={`sub last ${is_comment ? 'open isComment' : ''}`}>
                <CommnentSetupComponent
                    f={f}
                    checked={is_comment_required}
                    onChange={onOption.changeIsCommentRequired}
                    defaultValue={is_comment_placeholder}
                    refComment={refComment}
                    onChangeRef={onEdit.changeOption}
                />
            </div>
        </OptionStyled>
    )
    
}