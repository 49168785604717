import { useContext } from 'react'
import PropsContext from 'context/props'
import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import Question from 'styled/Survey/Edit/Question'

export default () => {
    const { onQuestion, onEdit, states, refQuestion } = useContext(PropsContext)
    const { question, img_src, module_type }= states.row._question
    
    const { formatMessage: f } = useIntl()

    const onChange = onEdit.changeQuestion
    const onAttach = {
        remove: onQuestion.removeImageAttach,
        open: onQuestion.openImageAttach
    }

    return (
        <Question 
            ref={refQuestion} 
            question={question}
            module_type={module_type}
            name="question" 
            rows={8} 
            img_src={img_src} 
            placeholder={f({id: `component.Survey.Edit.Question.question`})}
            maxlength={getMaxlength('component.Survey.Edit.Question.question')}
            onChange={onChange}
            onAttach={onAttach}
        />
    )
}