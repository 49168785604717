import { Grid, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { 
    ArrowDropUp as ArrowDropUpIcon,
    ArrowDropDown as ArrowDropDownIcon,
    ArrowLeft as ArrowLeftIcon,
    ArrowRight as ArrowRightIcon
} from '@material-ui/icons'
import { withBreakPoints } from "hoc"


const MyButton = withStyles(theme => ({
    root: {
        background: 'white'
    }
}))(props => <Button variant="outlined" size="small"{...props}/>)


const Component = (props) => {
    const { breakpoints, onChange } = props

    const { xs } = breakpoints

    return (
        <Grid container direction={xs ? 'row' : 'column'} alignItems="center" justifyContent="center" spacing={2}>
            <Grid item>
                <MyButton aria-label="move selected right" onClick={() => onChange('left')}>
                    {xs ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
                </MyButton>
            </Grid>
            
            <Grid item>
                <MyButton aria-label="move selected left" onClick={() => onChange('right')}>
                    {xs ? <ArrowDropUpIcon/> : <ArrowLeftIcon/>}
                </MyButton>
            </Grid>
        </Grid>
    )
}


/*ArrowDropUp as ArrowDropUpIcon,
ArrowDropDown as ArrowDropDownIcon,
ArrowDropLeft as ArrowDropLeftIcon,
ArrowDropRight as ArrowDropRightIcon*/


export default withBreakPoints(Component)