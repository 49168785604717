import { useIntl } from 'react-intl'
import { getMaxlength } from 'policy'
import { Avatar } from '@material-ui/core'
import { IOSSlider } from 'styled/Slider'
import { InputUnBorderStyled } from 'styled/Input'
import { RemoveIcon, AddIcon } from 'styled/Icon'
import { useStyles, WrapperStyled,TableStyled, AddTableStyled } from 'styled/Survey/Edit/Scale2Example'
import { withSurveyEditFocus } from 'hoc'
import { showAddButton } from '../05/Example'

const Example = (props) => {
    const { onExample, onEdit, states, refExamples, refScales } = props

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    const { _modules, _option } = states.row
    const rows = _modules
    const { zero_number } = _option

    const len = rows.length

    return (
        <WrapperStyled>
            <TableStyled>
                {
                    rows.map((c, i) => (
                        <div key={c.survey_module_no}>
                            <div>
                                <div><Avatar className={classes.small}>{(c.indexs)}</Avatar> </div>
                            </div>
                            <div>
                                <InputUnBorderStyled 
                                inputRef={ref => refExamples.current[i] = ref} 
                                defaultValue={c.answer} 
                                placeholder={f({id: 'component.Survey.Edit.07.Example.Input'})}
                                inputProps={{maxLength: getMaxlength('component.Survey.Edit.07.Example')}}
                                name={`answer`}
                                onKeyPress= {(e) => e.key === 'Enter' && onExample.enterPoint(i)}   
                                onChange={(e) => onEdit.changeModules(e, '_modules', i)}
                                />

                                <div style={{display: 'none'}}>
                                    <IOSSlider 
                                        ref={ref => refScales.current[i] = ref} 
                                        defaultValue={c.point} 
                                        valueLabelFormat={(value) => `${value}${f({id: 'component.Survey.Edit.07.Example.point'})}`} 
                                        min={0} 
                                        max={50} 
                                        valueLabelDisplay="on"
                                        name="point"
                                        onChange={onEdit.changeOption}
                                    />
                                </div>
                            </div>
                          
                            <div>
                                <div>{len > 2 && <RemoveIcon onClick={() => onExample.removePoint(c.survey_module_no)}/>}</div>
                            </div>
                        </div>
                    ))
                }
            </TableStyled>
            {
                showAddButton(rows.length, zero_number) && (
                    <AddTableStyled>
                       <AddIcon onClick={onExample.appendPoint}/>
                    </AddTableStyled>
                )
            }
        </WrapperStyled>
    )
}

export default withSurveyEditFocus('examples')(Example)