import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import RatingIcon from 'styled/Survey/RatingIcon'

const MyIconButton = withStyles(({
    root: (props: { selected: number }) => {
        const { selected } = props

        return { 
            background: selected ? 'rgba(0, 0, 0, .1)' : 'transparent'
        }
    }
}))(IconButton)

function IconComponent(props: any) {
    const { onOption, states } = props
    const { row } = states
    const { _option } = row
    const { icon_type } = _option

    const isStar = icon_type === 'star'
    const isEmoticon = icon_type === 'emoticon'
    const isFavorite = icon_type === 'favorite'
    const isNotifi = icon_type === 'notifi'
    const isEmoji = icon_type === 'emoji'
    const isCloud = icon_type === 'cloud'
    
    return (
        <>
        <MyIconButton onClick={() => onOption.changeIconType('star')} color={isStar ? 'primary' : 'default'} selected={isStar ? 1 : 0}><RatingIcon selected={isStar} icon_type='star'/></MyIconButton>
        <MyIconButton onClick={() => onOption.changeIconType('emoticon')} color={isEmoticon ? 'primary' : 'default'} selected={isEmoticon ? 1 : 0}><RatingIcon selected={isEmoticon} icon_type='emoticon'/></MyIconButton>
        <MyIconButton onClick={() => onOption.changeIconType('favorite')} color={isFavorite ? 'primary' : 'default'} selected={isFavorite ? 1 : 0}><RatingIcon selected={isFavorite} icon_type='favorite'/></MyIconButton>
        <MyIconButton onClick={() => onOption.changeIconType('notifi')} color={isNotifi ? 'primary' : 'default'} selected={isNotifi ? 1 : 0}><RatingIcon selected={isNotifi} icon_type='notifi'/></MyIconButton>
        <MyIconButton onClick={() => onOption.changeIconType('emoji')} color={isEmoji ? 'primary' : 'default'} selected={isEmoji ? 1 : 0}><RatingIcon selected={isEmoji} icon_type='emoji'/></MyIconButton>
        <MyIconButton onClick={() => onOption.changeIconType('cloud')} color={isCloud ? 'primary' : 'default'} selected={isCloud ? 1 : 0}><RatingIcon selected={isCloud} icon_type='cloud'/></MyIconButton>
        </>
                
    )
}

export default IconComponent