// 보기 순서 변경
import { ReactNode } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

interface Props {
    id: string;
    i: number;
    onChange: (a: any) => void;
    children: ReactNode;
}

const styles = {
    display: 'flex',
    alignItems: 'center',
    gap: 3
}

function OrderComponent(props: Props) {
    const { id, i, children } = props

    return (
        <Draggable draggableId={id} index={i}>
            {(provided, snapshot) => {
                const itemStyles = {
                    ...provided.draggableProps.style,
                    ...styles,
                    marginTop: i === 0 ? 0 : 5
                }

                return (
                    <div ref={provided.innerRef} {...provided.draggableProps} style={itemStyles}>
                        <div  {...provided.dragHandleProps}>
                            <DragIndicatorIcon color="action" style={{width: 15, marginTop: 5}}/>
                        </div>
                        {children}
                    </div>
                )
            }}
        </Draggable>
    )
}

export default OrderComponent

