import { Avatar } from '@material-ui/core'
import { IOSSlider } from 'styled/Slider'
import { useIntl } from 'react-intl'
import { RemoveIcon } from 'styled/Icon'
import { useStyles } from 'styled/Survey/Edit/Scale1Example'

const Answer = (props) => {
    const { onAnswer, onEdit, states, refAnswers } = props

    const { _answers, } = states.row
    const rows = _answers

    const { formatMessage: f } = useIntl()

    const classes = useStyles()

    // 해당값이 없으면 오류가 나서 삭제안하고 화면을 가린다
    return (
        <div style={{display: 'none'}}>
        {
            rows.map((c, i) => (
                <div key={c.survey_module_answer_no}>
                    <div>
                        <div><Avatar className={classes.small}>{(c.indexs)}</Avatar> </div>
                    </div>
                    <div>
                        <IOSSlider 
                            ref={ref => refAnswers.current[i] = ref} 
                            defaultValue={c.point} 
                            valueLabelFormat={(value) => `${value}${f({id: 'component.Survey.Edit.06.Answer.point'})}`} 
                            min={0} 
                            max={50} 
                            valueLabelDisplay="on"
                            name="point"
                            onChange={onEdit.changeOption}
                        />
                    </div>
                    <div>{i > 1 && <RemoveIcon onClick={() => onAnswer.removePoint(c.survey_module_answer_no)}/>}</div>
                </div>
            ))
        }
        </div>
    )
}


export default Answer