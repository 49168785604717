import styled from 'styled-components'

const WrapperStyled = styled.div`
    display: ${props => props.show === 'image' ? 'block' : 'none'};
    & > div + div {
        margin-top: 1em;
    }
`
export default (props) => {
    const { show, children } = props

    return (
        <WrapperStyled show={show}>
            {children}
        </WrapperStyled>
    )
}