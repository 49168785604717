import { useContext } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid, { GridProps } from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import NpsButtonComponent from './NpsButton'
import ScaleComponent from './Scale'
import ReverseComponent from './Reverse'
import LabelComponent from './Label'
import ExampleComponent from './Example'

export const ScalePaper = withStyles({
    root: {
        padding: 5,
        position: 'relative',
        '& + &': {
            marginTop: 5
        }
    }
})(props => <Paper {...props} variant='outlined'/>)


export const ScaleTitle = withStyles(theme => ({
    root: () => {
        let style = {}

        return {
            padding: 10,
            ...style,
            '& + &': {
                marginTop: 5
            }
        }
    }
}))(props => <Typography {...props} variant='body2'/>)


export const ScaleParentGrid = withStyles({
    root: {

    }
})(props => <Grid {...props} container spacing={1} alignItems='center'></Grid>)

export const ScaleTitleGrid = withStyles({
    root: {

    }
})((props: GridProps) => {
    const { xs, sm, md } = props

    return (
        <Grid 
            {...props} 
            item 
            xs={typeof xs === 'undefined' ? 3 : xs} 
            sm={typeof sm === 'undefined' ? 4 : sm} 
            md={typeof md === 'undefined' ? 4 : md}
        />
    )
})

export const ScaleInputGrid = withStyles({
    root: {

    }
})((props: GridProps) => {
    const { xs, sm, md } = props

    return (
        <Grid 
            {...props} 
            item 
            xs={typeof xs === 'undefined' ? 9 : xs} 
            sm={typeof sm === 'undefined' ? 8 : sm} 
            md={typeof md === 'undefined' ? 8 : md}
        />
    )
})

function Edit05Component() {
    const { states, refLabels, refExamples, onExample, onEdit, onOption } = useContext<any>(PropsContext)
    const { row } = states
    const { _option } = row
    const { nps } = _option

    const { formatMessage: f } = useIntl()

    const isNps = nps ? 1 : 0
    
    return (
        <>
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            <ScalePaper>
                <ScaleParentGrid>
                    <ScaleTitleGrid>
                        <ScaleTitle>Net Promoter Score®</ScaleTitle>
                    </ScaleTitleGrid>
                    <ScaleInputGrid>
                        <NpsButtonComponent states={states} onOption={onOption} />
                    </ScaleInputGrid>
                </ScaleParentGrid>
            </ScalePaper>
            {
                !isNps && (
                    <>
                    <ScalePaper>
                        <ScaleParentGrid>
                            <ScaleTitleGrid>
                                <ScaleTitle>{f({id: 'component.Survey.Edit.05.Component.subtitle.scale'})}</ScaleTitle>
                            </ScaleTitleGrid>
                            <ScaleInputGrid>
                                <ScaleComponent type="one" states={states} onExample={onExample} onOption={onOption}/>
                            </ScaleInputGrid>
                        </ScaleParentGrid>
                    </ScalePaper>
                    <ScalePaper>
                        <ScaleParentGrid>
                            <ScaleTitleGrid>
                                <ScaleTitle>{f({id: 'component.Survey.Edit.05.Component.subtitle.sort'})}</ScaleTitle>
                            </ScaleTitleGrid>
                            <ScaleInputGrid>
                                <ReverseComponent type="one" states={states} onOption={onOption} />
                            </ScaleInputGrid>
                        </ScaleParentGrid>
                    </ScalePaper>
                    </>
                )
            }
            <ScalePaper>
                <ScaleParentGrid>
                    <ScaleTitleGrid>
                        <ScaleTitle>{f({id: 'component.Survey.Edit.05.Component.subtitle.label'})}</ScaleTitle>
                    </ScaleTitleGrid>
                    <ScaleInputGrid>
                        <LabelComponent type="one" states={states} refLabels={refLabels}  onEdit={onEdit}/>
                    </ScaleInputGrid>
                </ScaleParentGrid>
            </ScalePaper>
        </SubTabBox>
        <ExampleComponent states={states} refExamples={refExamples}  onExample={onExample} onEdit={onEdit}/>
        </>
    )
}

export default Edit05Component