import { useCallback, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import FormatDateComponent from './FormatDate'
import FormatDotComponent from './FormatDot'
import { Props as ParentProps, Date, Dot } from './Format'

interface Props {
    date: Date;
    dot: Dot;
    onChange: ParentProps['onChange'];
}

export type OnChange<T> = (event: ChangeEvent<{ value: T }>) => void;

export const SectionTitle = withStyles({
    root: {

    }
})(props => <Typography {...props} component="div" variant="body2" color="textSecondary" gutterBottom/>)

// 포멧형식 합치기
const getValue = (date: Date, dot: Dot) => {
    switch(date) {
        case 'YYYYMMDD':
            return `YYYY${dot}MM${dot}DD`
        case 'MMDDYYYY':
            return `MM${dot}DD${dot}YYYY`
        case 'DDMMYYYY':
            return `DD${dot}MM${dot}YYYY`
        default:
            return `YYYY-MM-DD`
    }
}

function FormatDateDotBoxComponent(props: Props) {
    const { date, dot, onChange } = props

    const { formatMessage: f } = useIntl()

    const handleChange = useCallback((event: ChangeEvent<{name: string, value: string}>) => {
        const { name, value } = event.target

        if (name === 'date') {
            onChange('date_format_type', getValue(value, dot))
        } else if (name === 'dot') {
            onChange('date_format_type', getValue(date, value))
        }
    }, [date, dot, onChange])

    return (
        <>
            <SectionTitle>
                {f({id: 'component.Survey.Edit.09.Date.FormatDateDotBox.title'})}
            </SectionTitle>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormatDateComponent date={date} onChange={handleChange}/>
                <FormatDotComponent dot={dot} onChange={handleChange}/>
            </div>
        </>
    )
}

export default FormatDateDotBoxComponent