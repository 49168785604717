import { forwardRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import styled from 'styled-components'
import { getMaxlength } from 'policy'
import { SurveyEditCommentStyled } from 'styled/Input'
import { RemoveIcon, AddIcon } from 'styled/Icon'
import SubTabBox from 'styled/Survey/Edit/SubTabBox'
import { withSurveyEditFocus } from 'hoc'
import OrderDragComponent from '../01/OrderDrag'
import OrderDropComponent from '../01/OrderDrop'

const WrapperStyled = styled.div`
    padding-top: 0;

    & > div:last-child {
        margin-top: 10px;
        margin-bottom: 30px;
        text-align: center;
    }
`
const BoxStyled = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;

    & + & {
        margin-top: 10px;
    }

    & > div:first-child {
        width: calc(100% - 45px);
    }

    & > div:last-child {
        display: flex;
        justify-content: flex-end;
        width: 45px;
    }
`

const Rows = forwardRef((props, ref) => {
    const { len, i, f, survey_module_answer_no, answer, onEnter, onChange, onRemove } = props
    
    return (
        <BoxStyled>
            <div>
                <SurveyEditCommentStyled 
                    placeholder={f({id: 'component.Survey.Edit.11.Answer.Input'})}
                    inputProps={{maxLength: getMaxlength('component.Survey.Edit.11.Answer')}}
                    defaultValue={answer} onClick={(e) => e.target.focus() } 
                    inputRef={ref}
                    name={`answer`} 
                    onKeyPress= {(e) => e.key === 'Enter' && onEnter(i)}
                    onChange={(e) => onChange(e, '_answers', i)}
                />
            </div>
            <div>{len > 2 && <RemoveIcon onClick={() => onRemove(survey_module_answer_no)}/>}</div>
        </BoxStyled>
    )
})

const Component = () => {
    const { onAnswer, onEdit, states, refAnswers } = useContext(PropsContext)

    const { formatMessage: f } = useIntl()

    const rows = states.row._answers

    const len = rows.length

    return (
        <SubTabBox index={0} currentIndex={states.subTabIndex}>
            <WrapperStyled>
                <div>
                <OrderDragComponent id="answer-drag" onChange={onAnswer.changeOrder}>
                {
                    rows.map((c, i) => (
                        <OrderDropComponent key={c.survey_module_answer_no} id={`answer-item-${c.survey_module_answer_no}`} i={i}>
                        <Rows 
                            key={c.survey_module_answer_no} 
                            {...c} 
                            len={len}
                            i={i} 
                            f={f} 
                            ref={ref => refAnswers.current[i] = ref} 
                            onEnter={onAnswer.enter}
                            onChange={onEdit.changeModules}   
                            onRemove={onAnswer.remove}
                        />
                        </OrderDropComponent>
                    ))
                }
                </OrderDragComponent>
                </div>
                
                <div>
                    <AddIcon onClick={() => onAnswer.append()}/>
                </div>
            </WrapperStyled>
        </SubTabBox>
    )
}

export default withSurveyEditFocus('answers')(Component)