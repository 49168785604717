import { useContext } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { useIntl } from 'react-intl'
import OptionStyled from 'styled/Survey/Edit/Option'
import PropsContext from 'context/props'

export default () => {
    const { onOption, states } = useContext(PropsContext)
    const { required } = states.row._option

    const { formatMessage: f } = useIntl()
    
    return (
        <OptionStyled>
            <div className='main'>
                <div>
                    <FormControlLabel control={
                        <Checkbox checked={required} onChange={(e) => onOption.changeRequired(e)} name="required" color="primary"/>
                    } label={f({id:'component.Survey.Edit.10.Option.required'})}/>
                </div>
                
            </div>
        </OptionStyled>
    )
    
}