import { useContext } from 'react'
import { useIntl } from 'react-intl'
import PropsContext from 'context/props'
import styled from 'styled-components'
import oc from 'open-color'
import { makeStyles } from '@material-ui/core/styles'
import { 
    Grid, 
    List, 
    Card, 
    CardHeader, 
    ListItem, 
    ListItemText, 
    ListItemIcon, 
    Checkbox, 
    Divider, 
    Typography, 
    Chip
} from '@material-ui/core'
import { Done as DoneIcon, PhoneIphone as PhoneIphoneIcon } from '@material-ui/icons'
import CountryFlag from 'styled/CountryFlag'

import SwitchButtonComponent from './SwitchButton'
import { ContentBox } from '../Base/Component'

const useStyles = makeStyles(theme => ({
    cardHeader: {
        padding: theme.spacing(1, 2)
    },
    list: {
        height: 150,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
            height: 120
        }
    },
    paper: {
        padding: '2px 4px',
        display: 'flex',
        width: '100%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    typography: {
        marginBottom: '10px',
        fontSize: '15px',
        color: 'black',
        fontWeight: 900
    },
    formControlLabel: {
        fontSize: '12px',
        fontWeight: 900
    },
    flag: {
        width: '18px',
        height: '18px',
        borderRadius: '5px',
        boxShadow: '2px 2px 2px 2px #dee2e6'
    }
}))

const ListsStyled = styled.div`
    background: ${oc.gray[2]};
    padding: 18px 20px 20px 20px;
    border-radius: 5px;
    border: 1px solid ${oc.gray[2]};
`

const OnlyMobileStyled = styled.div`
    margin-top: 20px;
`

function PhoneComponent() {
    const { states, onTypePhone } = useContext(PropsContext)
    const { nationalLists, typePhoneAllChecked } = states
    const { type, only_mobile, national_flag } = states.row._option


    const { formatMessage: f } = useIntl()

    const classes = useStyles()
    
    const customList = (index, target, items) => (
        <Card variant="outlined">
            <CardHeader 
            className={classes.cardHeader} 
            avatar={<Checkbox name={target} checked={typePhoneAllChecked[target]} disabled={items.length === 0} onChange={onTypePhone.changeAll}/>} title={ f({id: `component.Survey.Edit.09.TypePhone.list.${index}`})} subheader={`${items.filter(c => c.checked).length}/${items.length} selected`}/>
            <Divider />
            <List className={classes.list}  dense component="div" role="list">
            {items.map(c => {
                const { code, checked } = c
                const labelId = `transfer-list-all-item-${code}-label`;
    
                return (
                    <ListItem key={code} role="listitem" button onClick={() => onTypePhone.change(target, code)} dense>
                        <ListItemIcon>
                            <Checkbox checked={checked} disableRipple inputProps={{ 'aria-labelledby': labelId }}/>
                        </ListItemIcon>
                        <ListItemIcon>
                            <CountryFlag code={code}/>
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={code} />
                    </ListItem>
                )
            })}
            <ListItem />
            </List>
        </Card>
    )

    return (
        <ContentBox display={Number(type === 'phone')}>
            <Typography className={classes.typography}>{f({id: 'component.Survey.Edit.09.TypePhone.typography'})}</Typography>
            <ListsStyled>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={5}>{customList(0, 'left', nationalLists.filter(c => c.visible))}</Grid>
                    <Grid item xs={12} sm={2}>
                        <SwitchButtonComponent onChange={onTypePhone.move}/>
                    </Grid>
                    <Grid item xs={12} sm={5}>{customList(1, 'right', national_flag)}</Grid>
                </Grid>
            </ListsStyled>
            <OnlyMobileStyled>
                <Chip
                    icon={<PhoneIphoneIcon />}
                    color={only_mobile ? "primary" : "default"}
                    label={f({id: 'component.Survey.Edit.09.TypePhone.onlyMobile'})}
                    clickable
                    size="small"
                    onDelete={() => {}}
                    onClick={onTypePhone.changeOnlyMobile}
                    deleteIcon={<DoneIcon />}
                />
            </OnlyMobileStyled>
        </ContentBox>
    )
}

export default PhoneComponent