import { memo, useContext, useState, useCallback, MouseEvent, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { QuestionType } from 'gql/survey_question_modules'
import { GET_VERIFY_SURVEY_QUESTION_CHANGE_MODULE_TYPE, VerifySurveyQuestionChangeModuleTypeProps } from 'gql/verify_survey'
import { SHOW as CONFIRMSHOW } from 'reducer/confirm'
import oc from 'open-color'
import Popover, { PopoverOrigin } from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListAltIcon from '@material-ui/icons/ListAlt'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { withSurveyModuleLists, withBreakPoints } from 'hoc'
import { BreakPointsProps } from 'hoc/BreakPoints'
import withPrevState, { UsePrevStateProps } from 'hoc/PrevState'
import Tooltip from 'styled/Custom/Tooltip'
import SurveyModuleLists from 'styled/Survey/Module/Lists'
import { ChangeModuleTypeContext } from 'container/Survey/Edit/Container'
import useSurveyOpen from 'hooks/useSurveyOpen'

const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
}

const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center'
}

interface Props extends UsePrevStateProps {
    survey_no: number;
    survey_question_no: number;
    question_type: QuestionType;
    selectedType: string;
    moduleLists: any;
    breakpoints: BreakPointsProps;
}

function ChangeModule(props: Props) {

    const { moduleLists, selectedType, survey_no, survey_question_no, question_type, usePrevState, breakpoints } = props

    const { xs } = breakpoints

    const { onChangeModuleType } = useContext(ChangeModuleTypeContext)

    const [ open ] = useSurveyOpen()

    const prevOpen = usePrevState(open)

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLButtonElement>(null)

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()

    const popoverRef = useRef<null | HTMLButtonElement>(null)

    const [getData, { data, loading, refetch }] = useLazyQuery(GET_VERIFY_SURVEY_QUESTION_CHANGE_MODULE_TYPE, {
        variables: {
            survey_no,
            survey_question_no
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const { verifySurveyQuestionChangeModuleType } = data

            handleOpen(verifySurveyQuestionChangeModuleType)
        },
        onError: (e) => {
            console.log(e)
        }
    }) 

    const handleClick = async(event: MouseEvent<HTMLButtonElement>) => {
        popoverRef.current = event.currentTarget

        if (!data) {
            getData({ 
                variables: {
                    survey_no,
                    survey_question_no
                }
            })

            return
        }

        if (prevOpen !== undefined) {
            if (Boolean(prevOpen) !== open) {
                if (refetch) {
                    refetch()
                    return
                }
            }
        } 

        const { verifySurveyQuestionChangeModuleType } = data

        handleOpen(verifySurveyQuestionChangeModuleType)
    }

    const handleOpen = (props: VerifySurveyQuestionChangeModuleTypeProps) => {
        if (!popoverRef.current) return

        const { status, can } = props
        const { open, is_response, is_logic, is_point, is_example_count, is_googlesheet } = status

        const color = oc.gray[8]
        const errorColor = oc.pink[9]

        const Icon = () => <CheckCircleIcon style={{ color: oc.green[8] }} fontSize="small"/>
        const ErrorIcon = () => <RemoveCircleIcon style={{ color: oc.pink[8] }} fontSize="small"/>

        function Child() {
            return (
                <>
                <List dense>         
                    <ListItem>
                        <ListItemIcon>
                            { !open ? <Icon/> : <ErrorIcon/> }
                        </ListItemIcon>
                        <ListItemText 
                            primary={f({id: 'component.Survey.Edit.ChangeModule.handleOpen.Child.item.0'})}
                            style={{ color: !open ? color : errorColor }}
                        />
                    </ListItem>
                    
                    <ListItem>
                        <ListItemIcon>
                            { !is_response ? <Icon/> : <ErrorIcon/> }
                        </ListItemIcon>
                        <ListItemText 
                            primary={f({id: 'component.Survey.Edit.ChangeModule.handleOpen.Child.item.1'})}
                            style={{ color: !is_response ? color : errorColor }}
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            { !is_logic ? <Icon/> : <ErrorIcon/> }
                        </ListItemIcon>
                        <ListItemText 
                            primary={f({id: 'component.Survey.Edit.ChangeModule.handleOpen.Child.item.2'})}
                            style={{ color: !is_logic ? color : errorColor }}
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            { (!is_point && !is_example_count) ? <Icon/> : <ErrorIcon/> }
                        </ListItemIcon>
                        <ListItemText 
                            primary={f({id: 'component.Survey.Edit.ChangeModule.handleOpen.Child.item.3'})} 
                            style={{ color: !is_point ? color : errorColor }}
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            { !is_googlesheet ? <Icon/> : <ErrorIcon/> }
                        </ListItemIcon>
                        <ListItemText 
                            primary={f({id: 'component.Survey.Edit.ChangeModule.handleOpen.Child.item.4'})} 
                            style={{ color: !is_googlesheet ? color : errorColor }}
                        />
                    </ListItem>
                </List>
                </>
            )
        }

        if (can) {
            setAnchorEl(popoverRef.current || null)
        } else {
            dispatch({ 
                type: CONFIRMSHOW,
                title: f({id: 'component.Survey.Edit.ChangeModule.blockTitle'}),
                detectiveFullScreen: 'xs',
                child: <Child/>
            })
        }

        popoverRef.current = null
    }

    const handleCloseModuleLists = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const moduleListsFilter = question_type === QuestionType.Explain ? moduleLists.filter((c: any) => c.kind === QuestionType.Explain) : moduleLists.filter((c: any) => c.kind !== QuestionType.Explain)

    if (xs) return null

    return (
        <>
        <Tooltip title={f({id: 'component.Survey.Edit.ChangeModule.title'})} placement="top">
            <IconButton  color="default" onClick={handleClick}>
                { loading ? <CircularProgress size={22}/> : <ListAltIcon  fontSize='small'/> }
            </IconButton>
        </Tooltip>
        <Popover
            id='copy-popover'
            elevation={24}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleCloseModuleLists}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            <List style={{minWidth: 210}}>
                <SurveyModuleLists moduleLists={moduleListsFilter} selectedType={selectedType} onChange={(module_type: string) => onChangeModuleType(module_type)}/>
            </List>
        </Popover>
        </>
    )
}

export default withBreakPoints(withSurveyModuleLists('body')(memo(withPrevState(ChangeModule))))